import React from "react";
import Chat from "../../Shared/Chat/Chat";
import PageComponent from "../../PageComponent/PageComponent";

const Values = () => {
  

  return (
    <div className="dark:bg-slate-600">
      <PageComponent
        title="Values - Cottage Home Care Services"
        description="Learn about the core values that define Cottage Home Care Services. Discover our dedication to compassion, integrity, respect, and excellence in providing home care. See how our values guide our actions and decisions, ensuring the highest quality of care for our clients."
        keywords="Cottage Home Care values, core values, compassion in home care, integrity in home care, respect in home care, excellence in home care, home care quality, client-centered values, home care principles, Cottage Home Care commitment"
        
      />
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20 ">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-2 font-sans text-3xl font-bold leading-none tracking-tight text-gary-600 sm:text-4xl md:mx-auto dark:text-gray-100">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="07690130-d013-42bc-83f4-90de7ac68f76"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">The</span>
            </span>{" "}
            Values, Of Cottage Home Care Services
          </h2>
          <hr className="mt-3 border-t-[1px] border-primary w-16 mx-auto " />
          <p className="text-base text-gray-700 md:text-lg font-medium mt-5 py-2 dark:text-gray-200">
            “HHA/PCA & CDPAP SERVICES THROUGHOUT NEW YORK CITY, NASSAU & SUFFOLK
            COUNTY, WESTCHESTER & ALBANY”
          </p>
        </div>
        <div className="grid max-w-screen-lg mx-auto space-y-6 lg:grid-cols-2 lg:space-y-0 lg:divide-x">
          <div className="space-y-6 sm:px-16">
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-primary sm:w-10 sm:h-10"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div className="dark:text-gray-100">
                <h6 className="mb-3 text-xl font-bold leading-5 ">
                  Personalized care:
                </h6>
                <p className="text-sm text-gray-900 dark:text-gray-100">
                  Cottage Home care services are tailored to the specific needs
                  and preferences of the individual, allowing them to receive
                  the personalized care they require.
                </p>
              </div>
            </div>
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-primary sm:w-10 sm:h-10"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5 dark:text-gray-100">
                  Comfort:
                </h6>
                <p className="text-sm text-gray-900 dark:text-gray-100">
                  Many individuals feel more comfortable receiving care in the
                  familiar surroundings of their own home, rather than in an
                  unfamiliar setting such as a hospital or care facility.
                </p>
              </div>
            </div>
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-primary sm:w-10 sm:h-10"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5 dark:text-gray-100">
                  Independence:
                </h6>
                <p className="text-sm text-gray-900 dark:text-gray-100">
                  Cottage Home care services can help individuals maintain their
                  independence and continue living in their own homes for as
                  long as possible.
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-6 sm:px-16">
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-primary sm:w-10 sm:h-10"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5 dark:text-gray-100">
                  Cost-effective:
                </h6>
                <p className="text-sm text-gray-900 dark:text-gray-100">
                  Cottage Home care services can be more cost-effective than
                  other forms of care, such as hospitalization or nursing home
                  care.
                </p>
              </div>
            </div>
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-primary sm:w-10 sm:h-10"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5 dark:text-gray-100">
                  Quality of life:
                </h6>
                <p className="text-sm text-gray-900 dark:text-gray-100">
                  Cottage Home care services can improve an individual's quality
                  of life by providing assistance with daily living activities,
                  reducing isolation and loneliness, and improving overall
                  well-being.
                </p>
              </div>
            </div>
            <div className="flex flex-col max-w-md sm:flex-row">
              <div className="mb-4 mr-4">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-primary sm:w-10 sm:h-10"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5 dark:text-gray-100">
                  Peace of mind:
                </h6>
                <p className="text-sm text-gray-900 dark:text-gray-100">
                  Cottage Home care services can provide peace of mind for both
                  the individual receiving care and their loved ones, knowing
                  that they are receiving high-quality care in the comfort of
                  their own home.
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="mt-10 text-center text-gray-900 italic dark:text-gray-100">
          Overall,{" "}
          <span className="font-medium">Cottage Home Care Services</span> offer
          a range of values and benefits for individuals who require assistance
          with daily living activities.
        </p>
      </div>
      <Chat />
    </div>
  );
};

export default Values;

import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Loading from '../Shared/Loading/Loading';
import NewLoading from '../NewLoading';

const UpdateForm = ({id, userInfo}) => {

    const [loading, setLoading] = useState(false)
    // console.log(id)

    const imageHostKey = process.env.REACT_APP_imgbb_key;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();




    const  updateUser = (name,photoURL)=>{

        const email = localStorage.getItem('email')

        const userInfo = {

            name:name,
            email:email,
            photoURL:photoURL,
        }


        fetch(`https://cottage-updated-server-v3.vercel.app/users`, {
                        method: "PATCH",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify(userInfo),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                               console.log(data)
                               toast.success('Profile Update successfully')
                               setLoading (false)
                               reset()
                        });
                    


    }
    const  updateName = (name)=>{

        const email = localStorage.getItem('email')

        const userInfo = {

            name:name,
            email:email,
            
        }


        fetch(`https://cottage-updated-server-v3.vercel.app/users`, {
                        method: "PATCH",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify(userInfo),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                               console.log(data)
                               toast.success('Profile Update successfully')
                               setLoading (false)
                               reset()
                        });
                    


    }





    const handelUpdate= (data) => {

        const username = data.name;
        //  console.log(data)

    setLoading(true)

    if(data?.url?.length > 0){

        const image = data.url[0]
        const formData = new FormData();
        formData.append('image', image);
        const url = `https://api.imgbb.com/1/upload?key=${imageHostKey}`
    
        fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(imgData => {
                // console.log(imgData.data.url)
                if (imgData.success) {
    
                    const info = {
                        firstName:username,
                        photoURL: imgData.data.url
    
                        
                
                    }
    
                    fetch(`https://cottage-updated-server-v3.vercel.app/users/${id}`, {
                        method: "PATCH",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify(info),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                               console.log(data)
                            //    toast.success('Profile Update successfully')
                            updateUser(username,imgData.data.url)
                               setLoading (false)
                        });
                    
    
                }
            })
    


    }
    else{

        

        const info = {

            firstName:username,
            

        }

        fetch(`https://cottage-updated-server-v3.vercel.app/users/${id}`, {
                        method: "PATCH",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify(info),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            //    console.log(data)
                            //    toast.success('Profile Update successfully')
                            updateName(username)
                               setLoading (false)
                        });



    }
   
   

       

    }









    return (
        <div>
           
            <div className="origin-top-right absolute md:-right-3 -right-12  w-[250px] md:w-[350px] rounded-md shadow-lg bg-slate-300 ring-1 ring-black ring-opacity-5 focus:outline-none px-6 py-4 dark:bg-slate-600">
                <div className="py-1">
                    {
                        loading &&
                        <NewLoading/>
                    }

                    <h3 className='dark:text-gray-100 text-center my-2 text-gray-600 font-semibold md:text-base text-sm'>Update Profile</h3>

                    <form onSubmit={handleSubmit(handelUpdate)} className="space-y-4 ng-untouched ng-pristine ng-valid">
                        <div className="space-y-1 text-sm">
                            <label htmlFor="username" className="block text-gray-600 dark:text-gray-100 md:text-base text-xs">Full Name</label>
                            <input type="text"

                                defaultValue={userInfo?.firstName}
                                {...register("name",{ required: "Name is required" })}
                                
                                id="name" placeholder="your name" className="w-full md:px-4 md:py-2 px-2 py-1 shadow-xl rounded-md dark:border-gray-700 bg-white dark:text-gray-100 focus:outline-none dark:bg-gray-400 dark:placeholder:text-gray-100 md:placeholder:text-base placeholder:text-xs" />

                                {errors.name && (
                                <p className="text-red-600">{errors.name.message}</p>
                                )}

                        </div>


                        <div className="space-y-0.5 text-sm">
                            <div className='flex items-center gap-4'>
                                <label htmlFor="firstName" className="block  dark:text-gray-100 md:text-base text-xs">
                                    Image
                                </label>

                                <small className='text-red-500'>[ Keep it into 200 KB ] </small>
                            </div>

                            <input type="file"

                                {...register("url")}
                                className=" md:px-4 md:py-2 px-2 py-1  shadow-xl file-input-secondary border-0  w-full dark:border-gray-700 bg-white dark:text-gray-100 focus:outline-none rounded-md dark:bg-gray-400 dark:placeholder:text-gray-100" />

                            
                        </div>




                    
                       







                        <button
                            // disabled={!accepted}
                            className="block  shadow-md w-full  md:px-4 md:py-2 px-2 py-1 text-center rounded-lg bg-primary hover:bg-secondary sign-button text-white">update</button>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default UpdateForm

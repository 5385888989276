import slugify from "slugify";

const pageMappings = {
  '/': { name: 'Home', path: '/' },
  '/cdpap': { name: 'CDPAP', path: '/cdpap' },
  '/hha': { name: 'HHA/PCA', path: '/hha' },
  '/nhtd': { name: 'NHTD', path: '/nhtd' },
  '/personalPayService': { name: 'Private Pay', path: '/personalPayService' },
  '/contacts': { name: 'Contact Us', path: '/contacts' },
  '/hhaCertification': { name: 'HHA Certification', path: '/hhaCertification' },
  '/resources': { name: 'Resources', path: '/resources' },
  '/helpDesk': { name: 'Help Desk', path: '/helpDesk' },
  '/covid': { name: 'COVID-19', path: '/covid' },
  '/faqs': { name: 'FAQs', path: '/faqs' },
  '/videos': { name: 'Video Gallery', path: '/videos' },
  '/pastEvent': { name: 'Past Events', path: '/pastEvent' },
  '/team': { name: 'Team Members', path: '/team' },
  '/blog': { name: 'Blog', path: '/blog' }
};

const dynamicMappings = {
  blog: '/blogs/' // base path for blog slugs
};

const fetchBlogSlugs = async () => {
  try {
    const response = await fetch('https://cottage-updated-server-v3.vercel.app/blogs'); // Adjust the endpoint as needed
    const data = await response.json();
    return data.blogs.map(blog => ({
      slug: slugify(blog.title, { lower: true, strict: true }),
      title: blog.title
    }));
  } catch (error) {
    console.error('Error fetching blog slugs:', error);
    return [];
  }
};

export const generateBreadcrumbData = async () => {
  const navigationHistory = JSON.parse(sessionStorage?.getItem('navigationHistory')) || [];
  const blogSlugs = await fetchBlogSlugs();

  const breadcrumbData = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": []
  };

  navigationHistory?.forEach((path, index) => {
    let pageDetail = pageMappings[path];

    if (!pageDetail) {
      // Detect and handle dynamic routes
      for (const route in pageMappings) {
        const regex = new RegExp(route.replace(/:[^\s/]+/g, '[^/]+')); // Convert dynamic route to regex
        if (regex.test(path)) {
          pageDetail = pageMappings[route];
          break;
        }
      }

      // Handle blog slugs
      if (!pageDetail && path.startsWith(dynamicMappings.blog)) {
        const slug = path.replace(dynamicMappings.blog, '');
        const blog = blogSlugs.find(b => b.slug === slug);
        if (blog) {
          pageDetail = { name: blog.title, path: `${dynamicMappings.blog}${slug}` };
        }
      }
    }

    if (pageDetail) {
      breadcrumbData.itemListElement.push({
        "@type": "ListItem",
        "position": index + 1, // Adjust position to start from 1
        "name": pageDetail.name,
        "item": `https://cottagehomecare.com${path}`
      });
    }
  });

  // console.log("Breadcrumb Data:", breadcrumbData);
  return breadcrumbData;
};

export const updateNavigationHistory = (pathname) => {
  let navigationHistory = JSON.parse(sessionStorage?.getItem('navigationHistory')) || [];
  
 

  // Add new path if it's not the last one in the history
  if (navigationHistory?.length === 0 || navigationHistory[navigationHistory?.length - 1] !== pathname) {
    navigationHistory.push(pathname);
    sessionStorage.setItem('navigationHistory', JSON.stringify(navigationHistory));
    // console.log("Updated Navigation History:", navigationHistory);
  }
};

import React from "react";

import blueTick from "../../../../assets/blue-tick-white-background-1.jpg";
import nurse from "../../../../assets/nurse-icon-1.1.png";
import heart from "../../../../assets/two-heart-icons-large.png";
import NHTD from "../../../../assets/nhtd-vector.png";
import CARE from "../../../../assets/privatePay.png";
import { Link } from "react-router-dom";
import MyImageComponent from "../../../Utils/MyImageComponent";

const Quality = () => {
  return (
    <div className="bg-white dark:bg-slate-600 ">
      <h3 className="text-[#322e51] text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-10 advertise dark:text-gray-100">
        The Service We Provide <br /> in-home care
      </h3>
      <hr className="border-[#00a6b2] border-t-2 w-12 mt-2 mb-8 mx-auto" />
      <h6 className="text-center md:text-2xl text-primary px-4 md:px-0 berlin dark:text-gray-100">
        HHA/PCA, CDPAP, NHTD & PRIVATE PAY SERVICES THROUGHOUT NEW YORK CITY{" "}
      </h6>
      <div className="grid md:grid-cols-2  w-[90%] mx-auto pb-14 pt-9 gap-5 ">
      <Link to='/cdpap'
          className="border border-primary dark:border-gray-100 lg:p-[50px] p-10"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="flex justify-center">
            <MyImageComponent src={heart} alt={heart} width='64' height='64' />
          </div>
          <h3 className="text-[#322e51] text-center font-medium md:font-semibold md:text-3xl text-2xl my-8 berlin custom-font dark:text-gray-100">
            CDPAP
          </h3>
          <div>
            <div className="flex items-center mb-1">
              <MyImageComponent src={blueTick} alt="" width='27' height='27' />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              You can choose your own Caregivers.
              </p>
            </div>
            <div className="flex items-center mb-1">
              <MyImageComponent src={blueTick} alt=""  width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Caregivers can be friends or family members.
              </p>
            </div>
            <div className="flex items-center mb-1">
              <MyImageComponent src={blueTick} alt=""  width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Allows you to receive help from your own home.
              </p>
            </div>
            <div className="flex items-center">
              <MyImageComponent src={blueTick} alt="" width='27' height='27' />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Allows you to be in the company of your loved ones.
              </p>
            </div>
          </div>
        </Link>
        <Link to='/hha' 
          className="border border-primary dark:border-gray-100 lg:p-[50px] p-10"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="flex justify-center">
            <MyImageComponent src={nurse} alt={nurse} width='64' height='64' />
          </div>
          <h3 className="text-[#322e51] text-center font-medium md:font-semibold text-2xl md:text-3xl my-8 space-y-1 berlin custom-font dark:text-gray-100">
            HHA/PCA
          </h3>
          <div className="">
            <div className="flex items-center mb-1">
              <MyImageComponent src={blueTick} alt="" width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              You receive an individualized care plan.
              </p>
            </div>
            <div className="flex items-center mb-1">
              <MyImageComponent src={blueTick} alt="" width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              You participate in all decision making.
              </p>
            </div>
            <div className="flex items-center">
              <MyImageComponent src={blueTick} alt="" width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Have a qualified HHA provide the services you need.
              </p>
            </div>
            <div className="flex items-center">
              <MyImageComponent  src={blueTick} alt="" width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Allows assistance with meal preparation, light housekeeping, and more.
              </p>
            </div>
            
          </div>
        </Link>
       
        <Link to='/nhtd'
          className="border border-primary dark:border-gray-100 lg:p-[50px] p-10"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="flex justify-center">
            <MyImageComponent  src={NHTD} alt="NHTD" width='64' height='64'/>
          </div>
          <h3 className="text-[#322e51] text-center font-medium md:font-semibold md:text-3xl text-2xl my-8 berlin custom-font dark:text-gray-100">
            NHTD
          </h3>
          <div>
            <div className="flex items-center mb-1">
              <MyImageComponent  src={blueTick} alt=""  width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Patients receive services based on their unique strengths, needs, and goals.
              </p>
            </div>
            <div className="flex items-center mb-1">
              <MyImageComponent  src={blueTick} alt="" width='27' height='27' />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Patients are the primary decision makers.
              </p>
            </div>
            <div className="flex items-center mb-1">
              <MyImageComponent  src={blueTick} alt="" width='27' height='27' />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Patients choose the services they receive.
              </p>
            </div>
            <div className="flex items-center">
              <MyImageComponent  src={blueTick} alt=""  width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Patients receive care right in their own home.
              </p>
            </div>
            <div className="flex items-center">
              <MyImageComponent  src={blueTick} alt="" width='27' height='27' />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Patients receive an individualized care plan.
              </p>
            </div>
          </div>
        </Link>
        <Link to='/personalPayService'
          className="border border-primary dark:border-gray-100 lg:p-[50px] p-10"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="flex justify-center">
            <MyImageComponent  src={CARE} alt="" width='64' height='64' />
          </div>
          <h3 className="text-[#322e51] text-center font-medium md:font-semibold md:text-3xl text-2xl my-8 berlin custom-font dark:text-gray-100">
            PRIVATE PAY
          </h3>
          <div>
            <div className="flex items-center mb-1">
              <MyImageComponent  src={blueTick} alt=""  width='27' height='27'  />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              You choose the care you need
              </p>
            </div>
            <div className="flex items-center mb-1">
              <MyImageComponent  src={blueTick} alt="" width='27' height='27' />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              Have a qualified HHA provide the services you need
              </p>
            </div>
            <div className="flex items-center mb-1">
              <MyImageComponent  src={blueTick} alt="" width='27' height='27' />
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              You participate in the decision making  
              </p>
            </div>
            <div className="flex items-center">
              <MyImageComponent  src={blueTick} alt=""  width='27' height='27'/>
              <p className="ml-4 text-[#67637e] font-normal text-sm md:text-xl dark:text-gray-100">
              You received an individualized care plan to suit your needs
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Quality;

import React, { useEffect } from "react";
import AboutCovid from "./AboutCovid";
import Banner from "./Banner";
import CovidSymption from "./CovidSymption";
import PreventSteps from "./PreventSteps";
import Response from "./Response";
import Chat from "../../Shared/Chat/Chat";
import PageComponent from "../../PageComponent/PageComponent";
import { useScroll } from "../Context/ScrollContext";

const Covid = () => {

  const { updateScrollBehavior, resetScrollBehavior } = useScroll();

  useEffect(() => {
    updateScrollBehavior('smooth');

    return () => {
      resetScrollBehavior(); // Reset to default behavior when unmounting
    };
  }, [updateScrollBehavior, resetScrollBehavior]);


  return (
    <div className=" px-5  dark:bg-slate-600">
       <PageComponent
        title="COVID-19 - Cottage Home Care Services"
        description="Stay updated with the latest COVID-19 safety measures and health services provided by Cottage Home Care. Learn more about our response to the coronavirus pandemic and how we ensure the safety and health of our community."
        keywords="COVID-19 updates, coronavirus safety measures, Cottage Home Care COVID response, health services during COVID, community health safety, pandemic response, health and safety protocols, COVID-19 healthcare services"
      />
      <div className="w-[95%] md:w-[80%] mx-auto">
        <Banner></Banner>
      </div>
      <Response></Response>
      <AboutCovid></AboutCovid>
      <CovidSymption></CovidSymption>
      <PreventSteps></PreventSteps>
      <Chat></Chat>

    </div>
  );
};

export default Covid;

import axios from 'axios';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { BiUserCircle } from 'react-icons/bi';
import { IoSendSharp } from 'react-icons/io5';
import { useQuery } from '@tanstack/react-query';
import { useLoaderData } from 'react-router-dom';
import { AuthContext } from '../../../Pages/Context/AuthProvider';


const MobileConversation = () => {

    const singleUser = useLoaderData()

    const id = singleUser?._id;
   
    const email = singleUser?.email;

    const { user } = useContext(AuthContext)
  
    // console.log(id)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const date = Date.now();


    const updateTime = (adminMessage) =>{

        const info= {
            date,
            email: singleUser?.email,
            adminMessage:adminMessage,
            chat:'active'
        }

        fetch(`https://cottage-updated-server-v3.vercel.app/users/time`, {
            method: "PUT",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(info),
          })
            .then((res) => res.json())
            .then((data) => {
              //    console.log(data)
              if (data.acknowledged) {
                toast.success('message send successfully')
                
              }
            });       

    }
    
    const { isLoading, data, refetch, } = useQuery(
        'chats',
        async () => {
            const { data } = await axios.get(
                `https://cottage-updated-server-v3.vercel.app/chats/allChats?email=${email}`
            );
            return data;
        },
        {
            refetchInterval: 1000,
        }
    );




    //

    // const el = document.getElementById('chat-feed');

    // if (el) {
    //   el.scrollTop = el.scrollHeight;
    // }
    

    //  console.log(data)

    const submitHandler = (data) => {

        const adminMessage = data?.message;
        

        const message = {
            firstName: user?.displayName,
            photoURL: user?.photoURL,
            email: email,
            time: date,
            userId: id,
            role: 'admin',
            messages: data?.message,
        };

        fetch(
            "https://cottage-updated-server-v3.vercel.app/chats",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(message),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                //    console.log(data)
                if (data.acknowledged) {
                   
                    updateTime(adminMessage)
                    reset()
                    // refetch()
                    
                }
            });
    };

   


  
    return (
       <div className='bg-[#EBF8F9] dark:bg-slate-600'>
         <div className=' w-[95%] mx-auto bg-[#EBF8F9] dark:bg-slate-600  '  >
            
          


            <div className='pb-10 border-4 ' >

                {
                    user?.email && data &&

                    <div className=' flex items-center gap-3 sticky top-0 bg-[#79a4a8] dark:bg-neutral-700 px-4 py-1 text-center'>
                            
                            {
                                data?.[0]?.photoURL &&

                                <img src={data?.[0]?.photoURL} alt={
                                    data?.[0]?.displayName} className='w-8 h-8 rounded-full' w='32' h='32' />

                            }
                            {

                               ! data?.[0]?.photoURL &&
                                <BiUserCircle className='w-12 h-12 rounded-full text-gray-300'></BiUserCircle>
                            }
                            {
                                data?.[0]?.firstName &&
                                <p className='text-sm font-semibold text-gray-100'>
                                    {
                                        data?.[0]?.firstName
                                    }
                                </p>
                            }
                            {
                                data?.[0]?.institute &&
                                <p className='text-xs  text-gray-100'>
                                    To: {
                                        data?.[0]?.institute
                                    }
                                </p>
                                }

                            {
                                data?.[0]?.officeName &&
                                <p className='text-xs  text-gray-100'>
                                       To: {
                                            data?.[0]?.officeName  
                                        }
                                </p>
                                
                            }
                            
                            
                                {
                                data?.[0]?.inquiry && 
                                <p className=' text-gray-200 text-xs '>
                                        Inquiry: {data?.[0]?.inquiry}  
                                </p>
                            }

                            {
                                data?.[0]?.service && 
                                <p className='text-gray-200 text-xs '>
                                        Service Type: {data?.[0]?.service}
                                </p>
                            }
                            {
                                data?.[0]?.time && 
                                <p className='text-gray-200 text-xs'>
                                        Date: {new Date(data?.[0]?.time).toLocaleString() }
                                </p>
                            }
                            {
                                data?.[0]?.email &&
                                
                                <a href={`mailto:${data?.[0]?.email}`} className=' text-gray-200 text-xs'>
                                        Email: <span className='hover:text-white'>
                                            {data?.[0]?.email}
                                        </span> 
                        </a>
                            }
                            
                            {
                                data?.[0]?.phone &&
                               

                                  <a href={`tel:+1${data?.[0]?.phone}`} className=' text-gray-200 text-sm font-semibold '>
                                        Phone: <span className='hover:text-white'>
                                            {data?.[0]?.phone}
                                        </span> 
                        </a>
                            }
                            

                            
                          

                    </div>

                }

                <div
                // id='chat-feed'       
                className='px-6  h-[50vh] overflow-y-scroll'>

                    {
                        data?.map(message => <div

                            key={message?._id}
                            className='py-2.5 '
                        >
                            {
                                !message?.role &&
                                <p className='flex items-center gap-3'>
                                        {
                                            message?.photoURL &&
                                            <img src={message?.photoURL} alt={`${message?.photoURL}`} className='w-6 h-6 rounded-full' w='24' h='24'/>
                                        }
                                        {
                                            !message?.photoURL &&
                                            <BiUserCircle className='w-6 h-6 rounded-full text-gray-400'></BiUserCircle>
                                        }
                                    <span className='bg-gray-400 text-white px-3 py-1.5 rounded-md shadow-md  '>  {message?.messages}</span>
                                </p>
                            }
                            {
                                message?.role &&
                                <p className='flex items-center gap-3 justify-end'>
                                    <span className='bg-primary text-white px-3 py-1.5 rounded-md shadow-md '>  {message?.messages}</span>
                                    <img src={message?.photoURL} alt={`${message?.photoURL}`} className='w-6 h-6 rounded-full' w='24' h='24' />

                                </p>
                            }



                        </div>)
                    }

                </div>

                <div>
                    <form onSubmit={handleSubmit(submitHandler)}>

                        <div className=' w-[90%] mx-auto relative '>
                            <input


                                {...register("message", { required: "message is required" })}


                                type="text" className=' px-4 py-2 bg-slate-400 focus:outline-none  rounded-md  w-[100%]  text-white focus:bg-slate-400' />

                            {errors.message && (
                                <p className="text-red-600">{errors.message.message}</p>
                            )}
                            <button>
                                <IoSendSharp className='absolute right-3 bottom-2 text-purple-900 text-2xl '></IoSendSharp>
                            </button>
                        </div>

                    </form>
                </div>

            </div>

        </div>
       </div>
    );
};

export default MobileConversation;
import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import ceo from '../../../../src/assets/ceo-mobile-thumnail.webp';
import './NewWelcome.css';
import MyImageComponent from '../../Utils/MyImageComponent';

const NewWelcome = () => {

    const handleDone = () => {
        // console.log(`Done after 5 loops!`)
    }
    const handleType = () => {
        // console.log(`Done after 5 loops!`)
    }
    return (
        <div className='bg-[#EBF8F9] '>
            <figure className="snip1390 dark:bg-slate-600 pb-3 ">
                <MyImageComponent src={ceo} alt="profile-sample3" className="profile 
                 h-[104px]  border-[5px] border-[#bfe3e5] dark:border-slate-400" width='120' height='120' />
                <figcaption>
                    <h2 className='dark:text-gray-100 font-semibold' >Navin Shivpal</h2>
                    <h4 className='dark:text-gray-100' >CEO - Cottage Home Care</h4>
                    <blockquote className='dark:bg-slate-800  w-[100%] mx-auto'>
                        <p className='mt-2 font-medium dark:text-gray-100'>
                        <Typewriter
                            words={['“A Message from our CEO & Administrator”']}
                            loop={1000}
                            cursor
                            cursorStyle='।'
                            typeSpeed={70}
                            deleteSpeed={50}
                            delaySpeed={1000}
                            onLoopDone={handleDone}
                            onType={handleType}
                        />

                        </p>
                        <p className='mt-1 font-medium custom-font dark:text-gray-100'> “Welcome”</p>
                        <p className='mt-1.5 text-justify  dark:text-gray-100'>“My commitment to providing a reliable, professional, caring and utmost friendly service through my agency is my number one priority. That is why if you have any serious inquiries or concerns or looking for an agency that truly cares, I urge you to call me directly. Those interested in Home Health Care and the CDPAP may also contact me at any time! <a className='text-primary' href="tel:+1347-975-2525">347-975-2525</a>.”</p>

                    </blockquote>
                </figcaption>
            </figure>
            
           
        </div>
    );
};

export default NewWelcome;
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import AuthProvider from "./Components/Pages/Context/AuthProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ScrollProvider } from "./Components/Pages/Context/ScrollContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ScrollProvider>
        <App />
        </ScrollProvider>
      </AuthProvider>
      
    </QueryClientProvider>
  
 
);

reportWebVitals();

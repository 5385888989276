import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper/modules";

// Import Swiper styles directly (ensure the path is correct in your project)
import "swiper/css";
import "swiper/css/pagination";

import "./Videos.css"; // Ensure this path is correct and the CSS is not empty
import VideoSlider from "./VideoSlider";
import SecondSlider from "./SecondSlider";
import PageComponent from "../../PageComponent/PageComponent";

function Videos() {
  
  window.scrollTo(0, 0);

  useEffect(() => {

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div
      className="unique-swiper-page no-scrollbar"
      style={{ height: "100vh", overflow: "hidden" }}>

      <PageComponent
        title="Video Gallery - Cottage Home Care Services"
        description="Explore our Video Gallery to see the heartwarming stories and experiences of our clients and caregivers at Cottage Home Care Services. Watch informative videos about our services, client testimonials, and educational content on home care. Get a glimpse into the compassionate care we provide across New York City, Nassau, Suffolk County, Westchester, and Albany."
        keywords="Video Gallery, Cottage Home Care Services videos, client testimonials, caregiver stories, home care services, educational videos, compassionate care, New York City, Nassau, Suffolk County, Westchester, Albany, home care education, client experiences"
      />

      <Swiper
        direction="vertical"
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
        speed={1000}
        // onSlideChange={handleSlideChange}
      >
        <SwiperSlide>
          <VideoSlider/>
        </SwiperSlide>
        <SwiperSlide>
          <SecondSlider />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Videos;

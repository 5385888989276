import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

import icon from '../../../../assets/blue-quote.png';
import CEO from '../../../../assets/ceo-update-picture.webp';
import MyImageComponent from '../../../Utils/MyImageComponent';


const Welcome = () => {
    const handleDone = () => {
        // console.log(`Done after 5 loops!`)
    }
    const handleType = () => {
        // console.log(`Done after 5 loops!`)
    }
    return (
        <div className='dark:bg-slate-600'>
            <div className='w-[95%] md:w-[90%] mx-auto py-5 mb-20 '>

                <div className=" md:relative w-full pt-[150px] ">
                    <div className='md:grid grid-cols-8  '>
                        <div className='col-span-2 md:-mt-[90px] z-10'
                        >
                            <div className='md:absolute  lg:left-[10%] ' data-aos="fade-down" data-aos-duration="3000">
                                <MyImageComponent src={CEO} className='lg:w-full md:w-[90%] h-96 lg:h-96 md:h-80 rounded-md w-[80%] mx-auto' alt="ceo" width='384' height='320' />
                            </div>
                        </div>
                        <div className=' col-span-6 dark:bg-slate-800 bg-[#EBF8F9] py-8 px-4 rounded-md lg:h-[380px] ' data-aos="fade-left" data-aos-duration="3000"
                        >
                            <div>
                                <div className=' md:w-[70%] mx-auto lg:pl-4  md:ml-[18%]  '>
                                    <MyImageComponent src={icon} alt="" className='w-[54px] h-[54px]' width='54' height='54' />
                                    <p className='roboto font-bold text-[17px] md:text-xl md:h-[40px] lg:h-[20px] berlin custom-font dark:text-white'>
                                        <Typewriter
                                            words={['“A Message from our CEO & Administrator”']}
                                            loop={1000}
                                            cursor
                                            cursorStyle='।'
                                            typeSpeed={70}
                                            deleteSpeed={50}
                                            delaySpeed={1000}
                                            onLoopDone={handleDone}
                                            onType={handleType}
                                        />

                                    </p>
                                    <p className='mt-3 roboto font-bold text-[17px] md:text-xl berlin custom-font dark:text-white'>
                                        “Welcome”
                                    </p>
                                    <p className='text-justify italic mt-2 text-[#67637E] dark:text-white'>“ My commitment to providing a reliable, professional, caring and utmost friendly service through my agency is my number one priority. That is why if you have any serious inquiries or concerns or looking for an agency that truly cares, I urge you to call me directly. Those interested in Home Health Care and the CDPAP may also contact me at any time! <a  className='text-primary' href="tel:+1347-975-2525">347-975-2525</a>.”  </p>

                                    <p className='bg-primary px-5 mt-6 py-[1px] w-[168px] '>
                                    </p>
                                    <p className='roboto mt-1 font-bold text-lg berlin custom-font dark:text-white'>
                                        CEO - Navin Shivpal
                                    </p>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Welcome;
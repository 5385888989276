import React from 'react';
import { BsFacebook, BsFillTelephoneFill, BsInstagram } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { FaXTwitter } from 'react-icons/fa6';
import GoogleTranslate from '../../GoogleTranslate/GoogleTranslate';

const TopBar = () => {
    return (
        <div className="dark:bg-slate-800">
            <div className="text-[#2E294E] block w-[95%] mx-auto berlin dark:text-gray-200">
                <div className="flex  md:justify-center justify-between items-center">
                    <div className="roboto px-4 md:px-8 py-3 lg:flex items-center lg:justify-between lg:w-[95%]">
                        <div className="lg:flex gap-8 hidden">
                            <div className="flex gap-4 items-center md:text-lg topbar-text">
                                <BsFillTelephoneFill />
                                <h1>
                                    Call <a href="tel:+1516-367-2266" className="text-primary dark:text-gray-300" aria-label="Call us at 516-367-2266">516-367-2266</a>
                                </h1>
                            </div>
                            <div className="flex gap-4 items-center md:text-lg topbar-text">
                                <TfiEmail />
                                <h1>
                                    Email <a href="mailto:info@cottagehomecare.com" className="text-primary dark:text-gray-300" aria-label="Email us at info@cottagehomecare.com">info@cottagehomecare.com</a>
                                </h1>
                            </div>
                        </div>
                        <div className="flex items-center gap-4 md:gap-5 md:text-lg font-medium text-primary">
                            <a href="tel:+1516-367-2266" className="text-primary lg:hidden md:text-2xl bg-hov2 dark:text-gray-300" aria-label="Call us at 516-367-2266">
                                <BsFillTelephoneFill />
                            </a>
                            <a href="mailto:info@cottagehomecare.com" className="text-primary md:text-2xl bg-hov2 lg:hidden dark:text-gray-300" aria-label="Email us at info@cottagehomecare.com">
                                <TfiEmail />
                            </a>
                            <a href="https://www.facebook.com/cottageHC" target="_blank" rel="noreferrer" aria-label="Visit our Facebook page">
                                <BsFacebook className="md:text-2xl bg-hov2 dark:text-gray-300 topbar-icon" />
                            </a>
                            <a href="https://www.instagram.com/cottagehcs/" target="_blank" rel="noreferrer" aria-label="Visit our Instagram profile">
                                <BsInstagram className="md:text-2xl bg-hov2 dark:text-gray-300 topbar-icon" />
                            </a>
                            <a href="https://twitter.com/cottagecareny" target="_blank" rel="noreferrer">
                                <FaXTwitter className="md:text-2xl bg-hov2 dark:text-gray-300 topbar-icon" aria-label="Visit our Twitter profile"/>
                            </a>
                            <a href="https://www.tiktok.com/@cottagehomecare" target="_blank" rel="noreferrer">
                                <FaTiktok className="md:text-2xl bg-hov2 dark:text-gray-300 topbar-icon" aria-label="Visit our Youtube profile"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <GoogleTranslate />
                    </div>
                </div>
            </div>
            <hr />
        </div>
    );
};

export default TopBar;

import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NewLoading from './NewLoading';
import { FaFileSignature, FaTimes } from 'react-icons/fa';
import './PopUp.css';

const PopUp = () => {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [minimize, setMinimize] = useState(true);
  const popupRef = useRef(null);

  const apiKey = process.env.REACT_APP_secureApiKey;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowPopup(true);
      setMinimize(false);
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleMinimize();
      }
    };

    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  const handleMinimize = () => {
    setMinimize(true);
    setTimeout(() => {
      setShowPopup(false);
      setMinimize(false);
    }, 500);
  };

  const smsHandler = (contactInfo) => {
    fetch("https://cottage-updated-server-v3.vercel.app/sms", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        'X-API-Key': `${apiKey}`,
      },
      body: JSON.stringify(contactInfo),
    })
      .then((res) => res.json())
      .then((data) => {
        // toast.success(data?.message)
      });
  };

  const submitHandler = async (data) => {
    setLoading(true);

    const contactInfo = {
      contact: data.phoneNumber
    };

    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("email", data.email);
    formData.append("contact", data.phoneNumber);
    formData.append("dob", data.dob);
    formData.append("medicate", data.medicate);
    formData.append("time", data.time);
    formData.append("file", data.file[0]);

    try {
      const response = await fetch("https://cottage-updated-server-v3.vercel.app/email", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();

      if (result.acknowledged) {
        smsHandler(contactInfo);
        toast.success("Message Sent successfully");
        reset();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email");
    } finally {
      setLoading(false);
      handleMinimize();
    }
  };

  return (
    <div>
      {showPopup && (
        <div>
          <div className={`popup-overlay z-50 ${minimize ? 'minimize' : ''}`}>
            <div data-aos="fade-up" data-aos-duration="3000">
              <div ref={popupRef} className={`popup z-50 relative text-white ${minimize ? 'minimize' : ''}`}>
                <button
                  onClick={handleMinimize}
                  className='absolute top-3 right-3 bg-red-600 p-2 rounded-full text-white'
                >
                  <FaTimes className='text-2xl' />
                </button>
                <div className='Poppins'>
                  <form
                    onSubmit={handleSubmit(submitHandler)}
                    className="md:max-w-2xl p-2 md:p-4 rounded-md shadow-xl lg:ml-0"
                  >
                    {loading && (
                      <div className='my-3'>
                        <NewLoading />
                      </div>
                    )}
                    <h1 className="md:text-2xl text-lg text-center md:font-medium custom-font">
                      {" "}
                      Eligibility Check
                    </h1>
                    <p className='mt-2 mb-1 text-center md:text-base text-sm'>If you want to get service please fill up the form.</p>

                    <div className="grid grid-cols-2 gap-3 md:gap-6 mt-2 md:mt-4 w-[320px] md:w-full">
                      <div className="md:space-y-1 text-sm">
                        <label
                          htmlFor="firstName"
                          className="block dark:text-gray-400"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          {...register("firstName", {
                            required: "First Name is required",
                          })}
                          id="firstName"
                          placeholder="First Name"
                          className="w-full px-4 md:py-2.5 py-1.5 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                        />
                        {errors.firstName && (
                          <p className="text-red-600">{errors.firstName.message}</p>
                        )}
                      </div>

                      <div className="md:space-y-1 text-sm">
                        <label htmlFor="phone" className="block dark:text-gray-400">
                          Last Name
                        </label>
                        <input
                          type="text"
                          {...register("lastName", {
                            required: "Last Name is required",
                          })}
                          id="LastName"
                          placeholder="Last Name"
                          className="w-full px-4 md:py-2.5 py-1.5 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                        />
                        {errors.lastName && (
                          <p className="text-red-600">{errors.lastName.message}</p>
                        )}
                      </div>

                      <div className="space-y-1 md:-mt-3 -mt-1 text-sm">
                        <label htmlFor="birthday" className="block dark:text-gray-400">
                          DOB
                        </label>
                        <input
                          type="text"
                          id="birthday"
                          name="birthday"
                          placeholder="DOB"
                          {...register("dob", { required: "DOB is required" })}
                          className="w-full px-4 md:py-2.5 py-1.5 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                        />
                        {errors.dob && (
                          <p className="text-red-600">{errors.dob.message}</p>
                        )}
                      </div>

                      <div className="space-y-1 md:-mt-3 text-sm">
                        <label htmlFor="medicate" className="block dark:text-gray-400 text-xs md:text-sm">
                          Medicaid No. [Optional]
                        </label>
                        <input
                          type="text"
                          id="MedicateNo"
                          name="MedicateNo"
                          placeholder="Ex:AB12345C"
                          {...register("medicate")}
                          className="w-full px-4 md:py-2.5 py-1.5 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100 placeholder:tracking-widest placeholder:"
                        />
                      </div>

                      <div className="space-y-1 md:-mt-3 -mt-1 text-sm">
                        <label htmlFor="email" className="block dark:text-gray-400">
                          Email
                        </label>
                        <input
                          type="email"
                          {...register("email")}
                          id="email"
                          placeholder="E-mail"
                          className="w-full px-4 md:py-2.5 py-1.5 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                        />
                      </div>

                      <div className="space-y-1 md:-mt-3 -mt-1 text-sm">
                        <label htmlFor="phoneNumber" className="block dark:text-gray-400">
                          Contact No.
                        </label>
                        <div className="relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="flag-icons-us"
                            viewBox="0 0 640 480"
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                          >
                            <g fillRule="evenodd" strokeWidth="1pt">
                              <path fill="#bd3d44" d="M0 0h924v37H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0zM0 37h924v36H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0zm0 73h924v37H0z" />
                              <path fill="#192f5d" d="M0 0h370v222H0z" />
                              <path
                                fill="#fff"
                                d="M33 8l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zM66 40l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm-330 65l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zM33 105l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10zm66 0l3 10h10l-8 6 3 10-8-6-8 6 3-10-8-6h10z"
                              />
                            </g>
                          </svg>
                          <input
                            type="tel"
                            id="phoneNumber"
                            placeholder="Enter Phone Number"
                            {...register("phoneNumber", {
                              required: "Phone number is required",
                              pattern: {
                                value: /^\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/,
                                message: "Please enter a valid US phone number",
                              },
                            })}
                            className="w-full pl-10 md:pl-12 px-4 md:py-2.5 py-1.5 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                          />
                        </div>
                        {errors.phoneNumber && (
                          <p className="text-red-600">{errors.phoneNumber.message}</p>
                        )}
                      </div>

                      <div className="space-y-1 md:-mt-3 -mt-1 text-sm">
                        <label htmlFor="time" className="block dark:text-gray-400">
                          Preferred time to Call
                        </label>
                        <input
                          type="text"
                          {...register("time", { required: "Time is required" })}
                          placeholder="Preferred time to Call"
                          className="w-full px-4 md:py-2.5 py-1.5 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                        />
                        {errors.time && (
                          <p className="text-red-600">{errors.time.message}</p>
                        )}
                      </div>

                      <div className="space-y-1 md:-mt-3 -mt-1">
                        <label htmlFor="file" className="block dark:text-gray-400 text-xs md:text-sm">
                          Attached Benefit Card
                        </label>
                        <input
                          type="file"
                          {...register("file")}
                          id="file"
                          className="md:w-[215px] w-[155px] px-4 md:py-1 py-1 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                        />
                      </div>
                    </div>

                    <button className="block w-full p-3 text-center rounded-md bg-primary hover:bg-secondary text-white mt-6">
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!showPopup && !minimize && (
        <div
          className={`w-9 h-9 md:w-12 md:h-12 bg-primary rounded-full fixed bottom-0 mb-[40vh] left-0 lg:z-50 z-30 cursor-pointer text-white flex justify-center items-center shadow-md ${minimize ? 'minimize' : ''}`}
          onClick={() => setShowPopup(true)}
        >
          <FaFileSignature className='text-xl md:text-3xl inline ml-2' />
        </div>
      )}
    </div>
  );
};

export default PopUp;

import React, { useEffect, useState } from 'react';


const Faq = () => {
 

  const [tab, setTab] = useState(1);

 



  const handleClick = (id) => {
    setTab((prevTab) => (prevTab === id ? 0 : id));
  };

  const handleRotate = (id) => (tab === id ? 'rotate-180' : '');
  const handleToggle = (id) => `transition-max-height ${tab === id ? 'open' : ''}`;

  const paidItems = [
    { id: 1, question:'What is the NHTD Waiver Program?', 
    answer: 'The NHTD Waiver Program helps Medicaid-eligible seniors and people with physical disabilities in New York receive comprehensive services while living in the community instead of in institutions.' },


    {
      id: 2,
      question: 'Who decides on the services?',
      answer: 'Participants choose their services, providers, and desired outcomes based on their unique needs and goals.'
    },
    {
      id: 3,
      question: 'Is there a housing subsidy available?',
      answer: 'Yes, eligible participants may receive a state-funded housing subsidy, subject to available funds. This subsidy is administered through the NHTD program but is not a waiver service.'
    },
    {
      id: 4,
      question: "What is the program's philosophy?",
      answer: 'The NHTD Waiver is based on the belief that individuals with physical disabilities and seniors have the right to control their lives, manage risks, and learn from their experiences while ensuring their health and welfare in the community.'
    },
    {
      id: 5,
      question: 'How is the Service Plan developed?',
      answer: 'Participants, as primary decision-makers, collaborate with providers and chosen others to create a Service Plan, leading to empowerment, independence, community inclusion, self-reliance, and productive activities.'
    },
    {
      id: 6,
      question: "What measures the program's success?",
      answer: "Participant satisfaction is a key measure of the NHTD program's success."
    },
  ];

  return (
    <div className=''>
      <div className=" ">
        <ul className="flex flex-col">
          {paidItems.map((item) => (
            <li key={item.id} className={` rounded-md dark:bg-slate-400 my-2  `}>
              <button onClick={() => handleClick(item.id)} 
                className={`w-full shadow-md focus:outline-none rounded-md ${tab === item.id ? 'text-white bg-primary dark:text-gray-100' : 'text-primary dark:text-primary bg-[#F6F6F6]'} `}>
                  <h2 className="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer mother-box  text-start md:text-center">
                    <p className='flex md:text-lg items-center gap-2 font-medium '>
                      <span className='w-full md:mx-auto  md:px-0 block '>{item.question}</span>
                    </p>
                    <span>
                      <svg className={` ${tab === item.id ? 'text-white ' : 'text-primary dark:text-primary'} fill-current dark:text-gray-100 h-6 w-6 transform transition-transform duration-100 ${handleRotate(item.id)}`} viewBox="0 0 20 20">
                        <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                      </svg>
                    </span>
                  </h2>
              </button>
              <div className={`overflow-hidden transition-all duration-500 ${handleToggle(item.id)}`}>
                {item.answer && <p className="p-3 text-gray-900 dark:text-gray-100">{item.answer}</p>}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Faq;

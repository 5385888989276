import DashBoardLayout from "../../../DashBoardLayout/DashBoardLayout";
import Blog from "../../Blog/Blog";
import EditBlogForm from "../../Blog/EditBlogForm";
import SingleBlog from "../../Blog/SingleBlog";
import Conversation from "../../Chat/Conversation/Conversation";
import MobileConversation from "../../Chat/Conversation/MobileConversation/MobileConversation";
import MissionVision from "../../FooterComponents/MissionVision";
import TeamMembers from "../../FooterComponents/TeamMembers";
import GraphPage from "../../GraphPage/GraphPage";
import AdminRoute from "../../Pages/AdminRoute/AdminRoute";
import Cdpaps from "../../Pages/CDPAP/Cdpaps";
import Contacts from "../../Pages/Contacts/Contacts/Contacts";
import Covid from "../../Pages/Covid/Covid";
import AllUsers from "../../Pages/DashBoard/AllUsers/AllUsers";
import Brooklyn from "../../Pages/DashBoard/Brooklyn/Brooklyn";
import CottageUsers from "../../Pages/DashBoard/CottageUsers/CottageUsers";
import EditForm from "../../Pages/DashBoard/Employee/EditForm";
import EmployeeForm from "../../Pages/DashBoard/Employee/EmployeeForm";
import AllOffice from "../../Pages/DashBoard/OfficeMessage/AllOffice";
import PdfUploader from "../../Pages/DashBoard/Pdf/PdfUploader";
import ErrorPage from "../../Pages/ErrorPage/ErrorPage";
import HhhaCertification from "../../Pages/HhhaCertification/HhhaCertification";
import History from "../../Pages/History/History";
import Home from "../../Pages/Home/Home/Home";
import Login from "../../Pages/LogIn/Login";
import Main from "../../Pages/Main/Main";
import Pca from "../../Pages/PCA/Pca";
import PrivateRoute from "../../Pages/PrivateRoute/PrivateRoute";
import Register from "../../Pages/Register/Register";
import Resources from "../../Pages/Resources/Resources";
import Services from "../../Pages/Services/Services/Services";
import TermCondition from "../../Pages/TermCondition/TermCondition";
import Values from "../../Pages/Values/Values";
import Chat from "../../Shared/Chat/Chat";
import PastEvent from "../../PastEvent/PastEvent";
import UpcomingEvent from "../../UpcomingEvent/UpcomingEvent";
import BrooklynRegistration from "../../Pages/BrooklynRegistration/BrooklynRegistration";
import RegisterUsers from "../../Pages/Registerusers/RegisterUsers";
import PassWordHandler from "../../Pages/PasswordHandler/PassWordHandler";
import ChristmasParty from "../../PastEvent/ChristmasParty";
import SchoolSupply from "../../PastEvent/SchoolSupply";
import CelebrityBrunch from "./../../PastEvent/CelebrityBrunch";
import MotherDay from "../../PastEvent/MotherDay";
import ToysDistribution from "../../PastEvent/ToysDistribution";
import Cdpap from "../../Pages/Services/Services/Cdpap";
import Hha from "../../Pages/Services/Services/Hha";
import Faqs from "../../FAQs/Faqs";
import AllTeamMembers from "../../FooterComponents/AllTeamMembers";
import EmployeeDetails from "../../EmployeeDetails/EmployeeDetails";
import HelpDesk from "../../Pages/HelpDesk/HelpDesk";
import GeneralQuery from "../../Pages/GeneralRequest/GeneralQuery";
import PayStub from "../../Pages/PayStub/PayStub";
import PayRoll from "../../Pages/Payroll/PayRoll";
import Supplies from "../../Pages/RequestSupplies/Supplies";
import Pto from "../../Pages/Pto/Pto";
import ClockInOut from "../../Pages/ClockInOut/ClockInOut";
import NHTD from "../../Pages/NHTD/NHTD";
import PrivatePay from "../../Pages/PrivatePay/PrivatePay";
import Videos from "../../Pages/Videos/Videos";
import SingleResources from "../../Pages/Resources/SingleResources";
import ScrollToTop from "../../Hooks/ScrollToTop";
import EditorRoute from "../../Pages/EditorRoute/EditorRoute";
import EditorLayOut from "../../../DashBoardLayout/EditorLayOut";
import EditorBlogForm from "../../Pages/DashBoard/BlogForm/EditorBlogForm";
import EditorDashBoard from "../../Pages/EditorDashBoard/EditorDashBoard";
import BlogForm from "../../Pages/DashBoard/BlogForm/BlogForm";
import PendingBlogs from "../../Pages/PendingBlog/PendingBlogs";


const { createBrowserRouter, Redirect } = require("react-router-dom");

const Route = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <Main />
      </>
    ),
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "/",
        element: <Home />,
      },

      {
        path: "/services",
        element: <Services></Services>,
      },
      {
        path: "/cdpap",
        element: <Cdpap />,
      },
      {
        path: "/hha",
        element: <Hha />,
      },
      {
        path: "/nhtd",
        element: <NHTD />,
      },
      {
        path: "/personalPayService",
        element: <PrivatePay />,
      },

      {
        path: "/contacts",
        element: <Contacts></Contacts>,
      },
      {
        path: "/hhaCertification",
        element: <HhhaCertification></HhhaCertification>,
      },
      {
        path: "/resources",
        element: <Resources></Resources>,
      },
      {
        path: "/helpDesk",
        element: <HelpDesk />,
      },
      {
        path: "/covid",
        element: <Covid></Covid>,
      },
      {
        path: "/faqs",
        element: <Faqs />,
      },
      {
        path: "/pastEvent",
        element: <PastEvent></PastEvent>,
      },
      {
        path: "/upcomingEvent",
        element: <UpcomingEvent></UpcomingEvent>,
      },
      {
        path: "/team",
        element: <TeamMembers></TeamMembers>,
      },
      {
        path: "/blog",
        element: <Blog></Blog>,
      },
      {
        path: "/chat",
        element: <Chat></Chat>,
      },
      {
        path: "/login",
        element: <Login></Login>,
      },
      {
        path: "/register",
        element: <Register></Register>,
      },
      {
        path: "/passwordHandler",
        element: <PassWordHandler />,
      },
      {
        path: "/allTeamMembers",
        element: <AllTeamMembers />,
      },
      {
        path: "/videos",
        element: <Videos />,
      },

      {
        path: "/termConditions",
        element: <TermCondition></TermCondition>,
      },
      {
        path: "/brooklynRegistration",
        element: <BrooklynRegistration />,
      },

      {
        path: "/conversation",
        element: <Conversation></Conversation>,
      },
      {
        path: "/values",
        element: <Values></Values>,
      },
      {
        path: "/history",
        element: <History></History>,
      },

      {
        path: "/clockInOut",
        element: <ClockInOut />,
      },

      // {
      //   path: "/blogs/:id",
      //   loader: ({ params }) =>
      //     fetch(
      //       `https://cottage-updated-server-v3.vercel.app/blogs/${params.id}`
      //     ),
      //   element: <PreviousSingleBlog/>
      // },
      {
        path: "/:slug",
        loader: ({ params }) =>
          fetch(`https://cottage-updated-server-v3.vercel.app/blogs/single/${params.slug}`),
        element: <SingleBlog />,
      },
      {
        path: "/employee/:id",
        loader: ({ params }) =>
          fetch(
            `https://cottage-updated-server-v3.vercel.app/employee/${params.id}`
          ),
        element: <EditForm></EditForm>,
      },
      {
        path: "/team/:id",
        loader: ({ params }) =>
          fetch(
            `https://cottage-updated-server-v3.vercel.app/employee/${params.id}`
          ),
        element: <EmployeeDetails />,
      },
      {
        path: "/singleBlogs/:id",
        loader: ({ params }) =>
          fetch(
            `https://cottage-updated-server-v3.vercel.app/blogs/${params.id}`
          ),
        element: <EditBlogForm></EditBlogForm>,
      },

      {
        path: "/missionVision",
        element: <MissionVision>
          
        </MissionVision>,
      },
      {
        path: "/toys-giveaway",
        element: <ToysDistribution />,
      },
      {
        path: "/christmas-party",
        element: <ChristmasParty />,
      },
      {
        path: "/school-supply",
        element: <SchoolSupply />,
      },
      {
        path: "/celebrity-branch",
        element: <CelebrityBrunch />,
      },
      {
        path: "/mother-day",
        element: <MotherDay />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <DashBoardLayout></DashBoardLayout>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "/dashboard",
        element: (
          <AdminRoute>
            <GraphPage></GraphPage>
          </AdminRoute>
        ),
      },

      {
        path: "user/:id",
        loader: ({ params }) =>
          fetch(
            `https://cottage-updated-server-v3.vercel.app/users/${params.id}`
          ),

        element: (
          <AdminRoute>
            <MobileConversation></MobileConversation>
          </AdminRoute>
        ),
      },

      {
        path: "pdf/:id",
        loader: ({ params }) =>
          fetch(
            `https://cottage-updated-server-v3.vercel.app/pdf/${params.id}`
          ),

        element: (
          <AdminRoute>
            <SingleResources />
          </AdminRoute>
        ),
      },

      {
        path: "/dashboard/cdpap",
        element: (
          <AdminRoute>
            <Cdpaps></Cdpaps>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/pca",
        element: (
          <AdminRoute>
            <Pca></Pca>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/users",
        element: (
          <AdminRoute>
            <AllUsers> </AllUsers>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/offices",
        element: (
          <AdminRoute>
            <AllOffice></AllOffice>
          </AdminRoute>
        ),
      },

      {
        path: "/dashboard/brooklyn",
        element: (
          <AdminRoute>
            <Brooklyn></Brooklyn>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/blogForm",
        element: (
          <AdminRoute>
            <BlogForm />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/employee",
        element: (
          <AdminRoute>
            <EmployeeForm></EmployeeForm>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/pendingBlog",
        element: (
          <AdminRoute>
            <PendingBlogs />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/cottageUsers",
        element: (
          <AdminRoute>
            <CottageUsers></CottageUsers>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/pdfForm",
        element: (
          <AdminRoute>
            <PdfUploader></PdfUploader>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/registerUsers",
        element: (
          <AdminRoute>
            <RegisterUsers />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/generalRequest",
        element: (
          <AdminRoute>
            <GeneralQuery />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/payStub",
        element: (
          <AdminRoute>
            <PayStub />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/payroll",
        element: (
          <AdminRoute>
            <PayRoll />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/supplies",
        element: (
          <AdminRoute>
            <Supplies />
          </AdminRoute>
        ),
      },

      {
        path: "/dashboard/pto",
        element: (
          <AdminRoute>
            <Pto />
          </AdminRoute>
        ),
      },
    ],
  },
  {
    path: "/editorDashboard",
    element: (
      <PrivateRoute>
        <EditorLayOut />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "/editorDashboard",
        element: (
          <EditorRoute>
            <EditorDashBoard />
          </EditorRoute>
        ),
      },
      {
        path: "blogForm",
        element: (
          <EditorRoute>
            <EditorBlogForm />
          </EditorRoute>
        ),
      },
    ],
  },
]);
export default Route;

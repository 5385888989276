import React from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import createSlug from '../Utils/slugify';


const BlogCard = ({ blog, isAdmin, refetch, isEditor,setLoading }) => {
    const { title, img, _id } = blog;
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const apiKey = process.env.REACT_APP_secureApiKey;
    const slug = createSlug(title);

    const deleteHandler = () => {
        const proceed = window.confirm("Are you sure, you want to delete this Blog");
        if (proceed) {
            fetch(`https://cottage-updated-server-v3.vercel.app/blogs/${_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': `${apiKey}`,
                },
            })
                .then(res => res.json())
                .then(data => {
                    if (data.deletedCount) {
                        toast.success('Blog Delete SuccessFully');
                        refetch();
                    }
                });
        }
    };

    return (
        <div className="flex justify-center" data-aos="zoom-in" data-aos-duration="2000">
            <div className="block max-w-sm rounded-lg bg-white shadow-lg dark:bg-slate-800 pb-4">
                <div className='relative h-64 overflow-hidden'>
                    <img
                        className="rounded-t-lg w-full h-60 object-cover transition-transform transform scale-100 hover:scale-110 ease-out duration-300 custom-zoom"
                        src={blog?.newImg || img}
                        alt={`${blog?.title}`}
                        w='100%'
                        h='240'
                    />
                </div>
                <div className="p-6 relative h-44">
                    {blog?.date && <h3 className='mb-2 text-neutral-600 dark:text-gray-100'>{blog.date}</h3>}
                    {blog?.newDate && (
                        <h5 className='mb-2 text-neutral-600 dark:text-gray-100'>
                            {new Date(blog.newDate).toLocaleString(undefined, options)}
                        </h5>
                    )}
                    <h1 className="text-lg font-medium leading-tight text-neutral-800 dark:text-gray-100 mb-8">
                        {title}
                    </h1>

                    {(isAdmin || isEditor) !== true ? (
                        <div className='absolute bottom-0'>
                            <Link className='' to={`/${slug}`} >
                                <button
                                    type="button"
                                    className="inline-block rounded bg-primary px-6 pt-2.5 pb-2.5 text-xs font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                    data-te-ripple-init
                                    data-te-ripple-color="light">
                                    Read More
                                </button>
                            </Link>
                        </div>
                    ) : (
                        <div className='flex items-center gap-3 absolute bottom-0 '>
                            <Link className='' to={`/${slug}`}>
                                <button
                                    type="button"
                                    className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                    data-te-ripple-init
                                    data-te-ripple-color="light">
                                    Read More
                                </button>
                            </Link>
                            <Link to={`/singleBlogs/${_id}`}>
                                <button
                                    type="button"
                                    className="inline-block rounded bg-secondary px-6 pt-2.5 pb-2 text-xs font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                    data-te-ripple-init
                                    data-te-ripple-color="light">
                                    Update
                                </button>
                            </Link>
                            <button
                                onClick={deleteHandler}
                                type="button"
                                className="inline-block rounded bg-red-500 px-6 pt-2.5 pb-2 text-xs font-medium leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                data-te-ripple-init
                                data-te-ripple-color="light">
                                Delete
                            </button>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default BlogCard;

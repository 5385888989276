import React from "react";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import footerLogo from "../../../assets/Cottage-Home.webp";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import "./Footer.css";
import MyImageComponent from "../../Utils/MyImageComponent";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-bg">
      <div className=" py-6">
        <div className="grid grid-cols-5 lg:grid-cols-5 w-[97%] mx-auto shadow-sm">
          {/* facebook  */}
          <a
            className="flex justify-center md:justify-center bg-[#5271B3] py-3 cursor-pointer"
            href="https://www.facebook.com/cottageHC"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Visit our Facebook page"
          >
            <div className="flex items-center gap-3 md:gap-5">
              <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-[#46619A] border-[1px] border-[#40598d] shadow-md">
                <FaFacebookF className="text-white md:text-2xl"></FaFacebookF>
              </div>
              <div className="text-white text-sm md:text-base hidden lg:block">
                <p>Facebook</p>
                <p>Join us on Facebook</p>
              </div>
            </div>
          </a>
          {/* end facebook */}

          {/* start instagram */}
          <a
            className="flex justify-center md:justify-center bg-gradient-to-r from-[#E0406D] to-[#6162DC] py-3 cursor-pointer"
            href="https://www.instagram.com/cottagehcs/"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Visit our Instagram Profile"
          >
            <div className="flex items-center gap-3 md:gap-5">
              <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-gradient-to-t from-[#F08535] via-[#D63573] to-[#7135B0] border-[1px] border-[#7135B0] shadow-md">
                <GrInstagram className="text-white md:text-2xl"></GrInstagram>
              </div>
              <div className="text-white text-sm md:text-base hidden lg:block">
                <p>Instagram</p>
                <p>Join us on Instagram</p>
              </div>
            </div>
          </a>
          {/* end instagram */}

          {/* start twitter */}
          <a
            className="flex justify-center md:justify-center bg-[#59C8FF] py-3 cursor-pointer"
            href="https://twitter.com/cottagecareny"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Visit our Twitter page"
          >
            <div className="flex items-center gap-3 md:gap-5">
              <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-[#4CB3DB] border-[1px] border-[#55afd2] shadow-md">
                <BsTwitter className="text-white md:text-2xl"></BsTwitter>
              </div>
              <div className="text-white text-sm md:text-base hidden lg:block">
                <p>Twitter</p>
                <p>Join us on Twitter</p>
              </div>
            </div>
          </a>
          {/* end twitter */}

          {/* start TikTok */}
          <a
            className="flex justify-center md:justify-center bg-gradient-to-r from-gray-700 via-gray-400 to-[#65C7CD] py-3 cursor-pointer"
            href="https://www.tiktok.com/@cottagehomecare"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Visit our Tiktok page"
          >
            <div className="flex items-center gap-3 md:gap-5">
              <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-gradient-to-t from-pink-400 via-[#65C7CD] to-gray-800 border-[1px] border-pink-300 shadow-md">
                <FaTiktok className="text-white md:text-2xl"></FaTiktok>
              </div>
              <div className="text-white text-sm md:text-base hidden lg:block">
                <p>TikTok</p>
                <p>Join us on TikTok</p>
              </div>
            </div>
          </a>
          {/* end TikTok */}

          {/* start YouTube */}
          <a
            className="flex justify-center md:justify-center bg-[#cc181e] py-3 cursor-pointer"
            href="https://www.youtube.com/channel/UCVn3ETmt_fcvPPivdcFT2uA"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Visit our Youtube channel"
          >
            <div className="flex items-center gap-3 md:gap-5">
              <div className="flex justify-center items-center w-8 h-8 md:w-14 md:h-14 bg-[#b008] border-[1px] border-[#880000b7] shadow-md">
                <BsYoutube className="text-white md:text-2xl"></BsYoutube>
              </div>
              <div className="text-white text-sm md:text-base hidden lg:block">
                <p>YouTube</p>
                <p>Join us on YouTube</p>
              </div>
            </div>
          </a>
          {/* end YouTube */}
        </div>
      </div>
      <div className="  px-4 np divide-y  text-white  footer-shade footer-opacity berlin ">
        <div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
          <div className="lg:w-1/3">
            <a
              rel="noopener noreferrer"
              href="#"
              className="flex justify-center space-x-3 lg:justify-start"
            >
              <div className="flex items-center justify-center w-16 h-16 rounded-full bg-white shadow-xl border-2 border-primary">
                <MyImageComponent
                  src={footerLogo}
                  alt="Cottage Home Care Services Logo"
                  className="p-2"
                  width="64"
                  height="64"
                />
              </div>
              <p className="self-center text-2xl">
                Cottage Home Care Services
              </p>
            </a>
          </div>

          <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-3 ">
            <div className="space-y-3">
              <Link
                to="/termConditions"
                className="tracking-wide uppercase text-gray-50 hover:text-primary"
              >
                Term & Condition
              </Link>
              <ul className="space-y-1">
                <li>
                  <Link
                    to="/missionVision"
                    className=" hover:text-primary"
                    rel="noopener noreferrer"
                  >
                    Mission & Vision{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/values"
                    rel="noopener noreferrer"
                    className=" hover:text-primary"
                  >
                    {" "}
                    Values
                  </Link>
                </li>
              </ul>
            </div>
            <div className="space-y-3">
              <Link
                to="/services"
                className="tracking-wide uppercase text-gray-50 hover:text-primary"
              >
                Service Area
              </Link>
              <ul className="space-y-1">
                <li>
                  <Link
                    to="/team"
                    className="hover:text-primary"
                    rel="noopener noreferrer"
                  >
                    Team Members
                  </Link>
                </li>

                <li>
                  <Link
                    to="/history"
                    rel="noopener noreferrer "
                    className="hover:text-primary"
                  >
                    History
                  </Link>
                </li>
              </ul>
            </div>
            <div className="space-y-3">
              <h3 className="uppercase dark:text-gray-50">Contact Info</h3>
              <ul className="space-y-1">
                <li>
                  <a
                    href="tel:+1516-367-2266"
                    rel="noreferrer"
                    aria-label="Call us on 516-367-2266"
                  >
                    Phone:{" "}
                    <span className="hover:text-primary "> 516-367-2266</span>
                  </a>
                </li>

                <li>
                  <a
                    href="mailto:info@cottagehomecare.com"
                    className="hover:text-primary "
                    rel="noreferrer"
                    aria-label="Email us on info@cottagehomecare.com"
                  >
                    {" "}
                    info@cottagehomecare.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="py-6 text-sm text-center text-gray-50">
          Copyright © {currentYear} Cottage Home Care - All Rights Reserved.{" "}
        </div>
      </div>
    </div>
  );
};

export default Footer;

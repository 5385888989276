import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import navLogo from "../../../assets/vector-logo.png";
import useAdmin from "../../Hooks/UseAdmin";
import { AuthContext } from "../Context/AuthProvider";
import "./Nav.css";
import { BiMenu } from "react-icons/bi";
import { RiDashboardLine } from "react-icons/ri";
import SideNav from "./SideNav";
import useEditor from "../../Hooks/useEditor";
import MyImageComponent from "../../Utils/MyImageComponent";
import { MdLightMode } from "react-icons/md";
import { IoDesktopOutline, IoMoonSharp } from "react-icons/io5";

const NavBar = () => {
  const [customShadow, setCustomShadow] = useState("shadow-none");
  const [isSticky, setIsSticky] = useState(false);
  const [navColor] = useState(false);

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "system"
  );
  const { user, logOut } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [isEditor] = useEditor(user?.email);

  // console.log(isEditor, isAdmin);

  // console.log(isAdmin)
  const handleLogOut = () => {
    logOut()
      .then(() => {})
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsSticky(true);
        setCustomShadow("shadow-xl");
      } else if (window.scrollY < 5) {
        setIsSticky(false);
        setCustomShadow("shadow-none");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const element = document.documentElement;

  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
  // console.log(darkQuery, "darkQuery")
  const options = [
    {
      icon: <MdLightMode />,
      text: "light",
    },
    {
      icon: <IoMoonSharp />,
      text: "dark",
    },
    {
      icon: <IoDesktopOutline />,
      text: "system",
    },
  ];

  const onWindowMatch = useCallback(() => {
    if (
      localStorage.getItem("theme") === "dark" ||
      (!("theme" in localStorage) && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }, [darkQuery, element.classList]);

  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");
        break;

      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");
        break;

      default:
        localStorage.removeItem("theme");
        onWindowMatch();
        break;
    }
  }, [theme, element.classList, onWindowMatch]);

  useEffect(() => {
    const handleDarkQueryChange = (e) => {
      if (!("theme" in localStorage)) {
        if (e.matches) {
          element.classList.add("dark");
        } else {
          element.classList.remove("dark");
        }
      }
    };

    darkQuery.addEventListener("change", handleDarkQueryChange);

    // Cleanup listener on component unmount
    return () => {
      darkQuery.removeEventListener("change", handleDarkQueryChange);
    };
  }, [darkQuery, element.classList]);

  // sticky top-0

  const navbarClasses = `bg-white z-50   sticky   ${
    isSticky
      ? "top-0 transition-all duration-1000"
      : "-top-20 transition-all duration-1500 "
  } `;

  return (
    <div
      className={`  ${navbarClasses}  md:z-50 dark:bg-slate-800  ${customShadow}  `}
    >
      <div
        className="px-4 pt-2 pb-4 md:mx-auto nav-size ml-auto"
        id="nav-shape"
      >
        <div className=" flex items-center md:justify-between md:w-full w-[95%] ">
          <Link
            to="/"
            aria-label="Cottage Home Care Services"
            title="Cottage Home Care Services"
            className="inline-flex items-center  "
          >
      <div>
  <div className="md:flex items-center hidden ">
    <MyImageComponent
      src={navLogo}
      className="nav-img shadow-2xl p-2 border-2 border-primary rounded-full dark:bg-gray-300"
      alt="Cottage Home Care Services Logo"
      width="100" // Set an appropriate width
      height="100" // Set an appropriate height
    />
    <h5
      className={` ml-4  font-semibold  text-[#00a6b2]  nav-font coco-gothic `}
    >
      <span className="text-shadow ">
        Cottage Home Care Services
      </span>
      <hr className="border-[1px] -mt-0.5 border-primary dark:border-gray-100 md:w-full w-[85%]" />
      <p className="font-semibold top-title md:text-center text-[#49465D] tracking-tighter md:tracking-normal dark:text-gray-300 md:ml-0 ml-2 ">
        THE RIGHT HOME CARE FOR YOU
      </p>
    </h5>
  </div>
  <div className="flex items-center md:hidden  ">
    <MyImageComponent
      src={navLogo}
      className="nav-img shadow-2xl p-2 border-2 border-primary rounded-full dark:bg-gray-300"
      alt="Cottage Home Care Services Logo"
      width="80" // Set an appropriate width
      height="80" // Set an appropriate height
    />

    <h5
      className={` ml-4 font-semibold text-[#00A6B2]  nav-font tracking-wider `}
    >
      Cottage Home <br /> Care Services
      <hr className="border-[1px] -mt-0.5 border-[#49465D] dark:border-gray-100 w-full" />
      <p className="font-semibold top-title md:text-center text-[#49465D] tracking-wide md:tracking-normal dark:text-gray-300 md:ml-0 ml-1">
        THE RIGHT HOME CARE FOR YOU
      </p>
    </h5>
  </div>
</div>

          </Link>

          <ul className="flex items-center hidden space-x-4 lg:flex berlin nav-list">
            <li>
              <Link
                to="/"
                aria-label="Home"
                title="Home"
                className={`font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation  dark:text-gray-300`}
              >
                Home
              </Link>
            </li>
            {/* <li>
              <NavLink
                to="/services"
                aria-label="services"
                title="services"
                className={`font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                Services
              </NavLink>
            </li> */}

            <li className="relative group">
              <button
                className={`dropdown-toggle font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300 `}
              >
                Services
              </button>
              <div className="dropdown-content group-hover:block text-base   rounded-sm w-full pt-2  dark:bg-slate-800 dark:hover:text-gray-100 ">
                {/* <Link to="/services">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase ">
                    Overview
                  </p>
                </Link> */}
                <Link to="/cdpap">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    CDPAP
                  </p>
                </Link>
                <Link to="/hha">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    hha/pca
                  </p>{" "}
                </Link>
                <Link to="/nhtd">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    NHTD
                  </p>{" "}
                </Link>
                <Link to="/personalPayService">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700 uppercase">
                    Private Pay
                  </p>{" "}
                </Link>
              </div>
            </li>

            <li>
              <Link
                to="/contacts"
                aria-label="Contact US"
                title="Contact US"
                className={`font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                Contact US
              </Link>
            </li>

            <li>
              <Link
                to="/hhaCertification"
                aria-label="HHA Certification"
                title="HHA Certification"
                className={`font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                HHA Certification
              </Link>
            </li>

            <li className="relative group">
              <button
                className={`dropdown-toggle font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300 `}
              >
                Resources
              </button>
              <div className="dropdown-content group-hover:block text-base  rounded-sm w-full pt-2  dark:bg-slate-800 dark:hover:text-gray-100 ">
                <Link to="/resources">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Resources
                  </p>
                </Link>
                <Link to="/helpDesk">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Help Desk
                  </p>
                </Link>
                <Link to="/covid">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Covid-19
                  </p>{" "}
                </Link>
                <Link to="/faqs">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    FAQs
                  </p>{" "}
                </Link>
              </div>
            </li>
            <li className="relative group">
              <button
                className={`dropdown-toggle font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300 `}
              >
                Community Outreach
              </button>
              <div className="dropdown-content group-hover:block text-base  rounded-sm w-full pt-2  dark:bg-slate-800">
                <Link to="/videos">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Video Gallery
                  </p>
                </Link>
                <Link to="/pastEvent">
                  {" "}
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Past Events
                  </p>
                </Link>
                <Link to="/UpcomingEvent">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Upcoming Events
                  </p>
                </Link>
                <Link to="/team">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Team Members
                  </p>
                </Link>

                {/* <Link to="/brooklynRegistration">
                  <p className="dark:text-gray-100 dark:hover:text-gray-700">
                    Brooklyn Registration
                  </p>
                </Link> */}
              </div>
            </li>

            <li>
              <Link
                to="/blog"
                aria-label="Blog"
                title="Blog"
                className={`font-medium    tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white   uppercase nav-text hover-underline-animation dark:text-gray-300`}
              >
                Blog
              </Link>
            </li>

            {user?.uid ? (
              <>
                <li className="nav-user">
                  <img
                    src={user?.photoURL}
                    title={user?.displayName}
                    className="rounded-full h-12 w-12 border-primary border-[2px] nav-text"
                    alt=""
                    w='48'
                    h='48'
                  />
                </li>

                <li className="nav-text ">
                  <button
                    onClick={handleLogOut}
                    className="bg-primary  lg:px-6 lg:py-3 px-3 pt-2  lg:text-base  rounded-full text-white bg-hov2  uppercase nav-text dark:text-gray-300 "
                    id="sign-out"
                  >
                    Sign Out
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/login"
                    aria-label="Sign Up"
                    title="Sign Up"
                    className={`font-medium  text-lg  tracking-wide text-[#49465D] transition-colors duration-200  
                                ${
                                  navColor && "customWhite"
                                } white  nav-text  dark:text-gray-300`}
                  >
                    <button
                      className="bg-primary  lg:px-6 lg:py-3 px-3 pt-2  lg:text-base  rounded-full text-white bg-hov2 uppercase nav-text  dark:text-gray-300 "
                      id="sign-in"
                    >
                      Sign In
                    </button>
                  </Link>
                </li>
              </>
            )}

            <li>
              <details className="dropdown dropdown-end ">
                <summary
                  type="button"
                  className=" text-4xl tracking-wide text-primary font-bold  duration-200   bg-transparent  outline-none  cursor-pointer dark:text-gray-300 mt-1"
                  style={{ listStyle: "none" }}
                >
                  <BiMenu></BiMenu>
                </summary>
                <ul className="p-2 shadow menu dropdown-content z-[1] bg-slate-200 dark:text-gray-100 rounded-box w-52 mt-5 dark:bg-slate-600 ">
                  {options.map((opt, index) => (
                    <button
                      key={index}
                      onClick={() => setTheme(opt?.text)}
                      className={` block w-full  ${
                        theme === opt?.text && "text-sky-600"
                      }`}
                    >
                      <div className="flex items-center gap-7 px-5 py-1.5  hover:bg-slate-300 mt-1 text-lg rounded-md">
                      {opt?.icon}

                        <p className="uppercase">{opt?.text}</p>
                      </div>
                    </button>
                  ))}

                  {user?.uid && (
                    <>
                      {isAdmin && (
                        <>
                          <li>
                            <Link
                              to="/dashBoard"
                              aria-label="Dash Board"
                              title="Dash Board"
                              className="hover:bg-slate-300"
                            >
                              <p
                                className={`fo font-medium  text-lg tracking-wide text-[#49465D] transition-colors duration-200  
               white   uppercase   dark:text-gray-100 flex items-center gap-5 dark:hover:text-white `}
                              >
                                <RiDashboardLine className="text-2xl"></RiDashboardLine>{" "}
                                Dash Board
                              </p>
                            </Link>
                          </li>
                        </>
                      )}
                    </>
                  )}
                  {user?.uid && (
                    <>
                      {isEditor && (
                        <>
                          <li>
                            <Link
                              to="/editorDashboard"
                              aria-label="Dash Board"
                              title="Dash Board"
                              className="hover:bg-slate-300"
                            >
                              <h1
                                className={`fo font-medium  text-lg tracking-wide text-[#49465D] transition-colors duration-200  
               white   uppercase   dark:text-gray-100 flex items-center gap-5 dark:hover:text-white `}
                              >
                                <RiDashboardLine className="text-2xl"></RiDashboardLine>{" "}
                                Dash Board
                              </h1>
                            </Link>
                          </li>
                        </>
                      )}
                    </>
                  )}

                </ul>
              </details>
            </li>
          </ul>

          <div className="lg:hidden">
            <div className="  transition duration-200 rounded focus:outline-none focus:shadow-outline z-50 absolute right-5 top-7 ">
              <SideNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;

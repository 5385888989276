import React from "react";
import "./VideosModal.css";
import LazyLoadVideo from "../../LazyloadVideo.js/LazyLoadVideo";

const VideosModal = ({ onClose, youtubeUrl }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-80 lg:bg-opacity-60 flex items-center justify-center z-50  "
      onClick={onClose}
    >
      <div
        data-aos="fade-down"
        data-aos-duration="1000"
        className="relative dark:bg-slate-600 w-[95%] md:max-w-2xl 2xl:max-w-4xl"
        onClick={handleModalClick}
        id="food-video"
      >
        <div>
          <div className="w-full shadow-md z-50">
            <LazyLoadVideo src={youtubeUrl} rounded={false}></LazyLoadVideo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosModal;

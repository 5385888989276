import React from 'react';
import Chat from '../../Shared/Chat/Chat';
import PageComponent from '../../PageComponent/PageComponent';

const TermCondition = () => {




    return (
      <div className="dark:bg-slate-600">

        <PageComponent
          title="Terms & Conditions - Cottage Home Care Services"
          description="Review the terms and conditions for using Cottage Home Care Services. Understand your rights and responsibilities as a user, our policies on privacy and data protection, and the guidelines for accessing and using our services. Ensure you're informed and compliant with our service agreements."
          keywords="Cottage Home Care terms and conditions, user rights and responsibilities, privacy policy, data protection guidelines, service agreements, terms of service, usage guidelines, Cottage Home Care policies, compliance, legal terms and conditions"
        />

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20 lg:pt-14">
          <h1 className="px-4 md:px-0 text-center text-2xl md:text-3xl text-primary playrify font-semibold dark:text-gray-100">
            Term & Condition Of Cottage Home Care Services
          </h1>

          <p
            className="text-center text-gray-700  font-medium w-[95%] 
                md:w-[75%] mx-auto pb-8 pt-10 dark:text-gray-100"
          >
            The specific terms and conditions of home care services may vary
            depending on the provider and the specific services being offered.
            However, here are some common terms and conditions that may be
            included:
          </p>
          <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
            <div className="p-8 border-b sm:border-r">
              <div className="max-w-md text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-[#EBF8F9] sm:w-16 sm:h-16">
                  <svg
                    className="w-8 h-8 text-primary sm:w-12 sm:h-12"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5 dark:text-gray-100">
                  Services Provided:
                </h6>
                <p className="mb-3 text-sm text-gray-900 dark:text-gray-100">
                  The home care service provider will specify the specific
                  services they will provide, including the frequency and
                  duration of the services.
                </p>
              </div>
            </div>
            <div className="p-8 border-b lg:border-r">
              <div className="max-w-md text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-[#EBF8F9] sm:w-16 sm:h-16">
                  <svg
                    className="w-8 h-8 text-primary sm:w-12 sm:h-12"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5 dark:text-gray-100">
                  Payment:
                </h6>
                <p className="mb-3 text-sm text-gray-900 dark:text-gray-100">
                  The cost of the services will be clearly outlined, including
                  any additional fees or charges that may apply.
                </p>
              </div>
            </div>
            <div className="p-8 border-b sm:border-r lg:border-r-0">
              <div className="max-w-md text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-[#EBF8F9] sm:w-16 sm:h-16">
                  <svg
                    className="w-8 h-8 text-primary sm:w-12 sm:h-12"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5 dark:text-gray-100">
                  Cancellation Policy:
                </h6>
                <p className="mb-3 text-sm text-gray-900 dark:text-gray-100">
                  The provider may have a policy for cancelling or rescheduling
                  services, and the terms and conditions of this policy will be
                  outlined.
                </p>
              </div>
            </div>
            <div className="p-8 border-b lg:border-b-0 lg:border-r">
              <div className="max-w-md text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-[#EBF8F9] sm:w-16 sm:h-16">
                  <svg
                    className="w-8 h-8 text-primary sm:w-12 sm:h-12"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5 dark:text-gray-100">
                  Liability
                </h6>
                <p className="mb-3 text-sm text-gray-900 dark:text-gray-100">
                  The provider may outline their liability in the event of any
                  injury or damages that occur during the provision of services.
                </p>
              </div>
            </div>
            <div className="p-8 border-b sm:border-b-0 sm:border-r">
              <div className="max-w-md text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-[#EBF8F9] sm:w-16 sm:h-16">
                  <svg
                    className="w-8 h-8 text-primary sm:w-12 sm:h-12"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5 dark:text-gray-100">
                  Privacy Policy:
                </h6>
                <p className="mb-3 text-sm text-gray-900 dark:text-gray-100">
                  The provider will specify how they handle client information
                  and confidentiality.
                </p>
              </div>
            </div>
            <div className="p-8">
              <div className="max-w-md text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-[#EBF8F9] sm:w-16 sm:h-16">
                  <svg
                    className="w-8 h-8 text-primary sm:w-12 sm:h-12"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5 dark:text-gray-100">
                  Termination:
                </h6>
                <p className="mb-3 text-sm text-gray-900 dark:text-gray-100">
                  The terms and conditions of terminating services will be
                  outlined, including any notice required and any fees or
                  charges that may apply.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Chat />
      </div>
    );
};

export default TermCondition;
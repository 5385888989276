import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { BiUserCircle } from "react-icons/bi";
import { useQuery } from '@tanstack/react-query';
import { AuthContext } from "../../Pages/Context/AuthProvider";
import Linkify from "react-linkify";
import { IoMdArrowRoundUp } from "react-icons/io";
const AdminConversation = ({ newUser, users, setLoad, load }) => {
  //  console.log(newUser,users);

  const chatDivRef = useRef();

  const id = newUser?._id;

  // const email = newUser?.email;
  let email = "";

  if (newUser) {
    email = newUser?.email;
  } else {
    email = users?.[0]?.email;
  }

  const { user } = useContext(AuthContext);

  // console.log(id)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const date = Date.now();

  const emailSend = (userName, email, messages, userMessage) => {
    const message = {
      userName,
      email,
      messages,
      userMessage,
    };

    // console.log(message)

    fetch(`https://cottage-updated-server-v3.vercel.app/adminMessage`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((res) => res.json())
      .then((data) => {
        //    console.log(data)
        if (data.acknowledged) {
          toast.success("Email send successfully");
        }
      });
  };

  const updateTime = (adminMessage) => {
    const info = {
      date,
      name: newUser?.name,
      email: email,
      chat: "active",
      adminMessage: adminMessage,
    };

    fetch(`https://cottage-updated-server-v3.vercel.app/users/time`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(info),
    })
      .then((res) => res.json())
      .then((data) => {
        //    console.log(data)
        if (data.acknowledged) {
          toast.success("message send successfully");
          setLoad(!load);
        }
      });
  };

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["chats", email],
    queryFn: async () => {
      const { data } = await axios.get(
        `https://cottage-updated-server-v3.vercel.app/chats/allChats?email=${email}`
      );
      return data;
    },
    refetchInterval: 1000,
  });

  // console.log(data)

  useEffect(() => {
    // Use setTimeout to scroll after a short delay (you can adjust the delay)
    setTimeout(() => {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }, 100); // Adjust the delay as needed
  }, [data]);

  
  // const el = document.getElementById('chat-feed');

  // if (el) {
  //   el.scrollTop = el.scrollHeight;
  // }

  const userName = data?.[0]?.firstName;

  let userMessage = "";

  for (let i = data?.length - 1; i >= 0; i--) {
    if (data?.[i]?.messages && data?.[i]?.role !== "admin") {
      userMessage = data?.[i]?.messages;
      break;
    }
  }

  if (
    userMessage === "" &&
    data?.[0]?.messages === null &&
    data?.[1]?.messages
  ) {
    userMessage = data?.[1]?.messages;
  }

  // console.log(data);

  // console.log(userMessage);

  // console.log(userMessage,data)

  const submitHandler = (data) => {
    const adminMessage = data?.message;
    const messages = data?.message;
    const message = {
      firstName: user?.displayName,
      photoURL: user?.photoURL,
      email: email,
      time: date,
      userId: id,
      role: "admin",
      messages: data?.message,
    };

    fetch("https://cottage-updated-server-v3.vercel.app/chats", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((res) => res.json())
      .then((data) => {
        //    console.log(data)
        if (data.acknowledged) {
          updateTime(adminMessage);
          emailSend(userName, email, messages, userMessage);
          reset();
          refetch();
        }
      });
  };

  const customComponentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      style={{ color: "blue" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  return (
    <div className=" w-full bg-[#EBF8F9] dark:bg-slate-600 ">
      <div className="pb-10 scroll-my-96 ">
        {user?.email && data && (
          <div className=" flex items-center gap-4 sticky top-0 bg-[#79a4a8] dark:bg-neutral-700 px-4 py-2 text-center">
            {data?.[0]?.photoURL && (
              <img
                src={data?.[0]?.photoURL}
                alt={data?.[0]?.displayName}
                className="w-12 h-12 rounded-full"
                w='48px'
                h='48px'
              />
            )}
            {!data?.[0]?.photoURL && (
              <BiUserCircle className="w-12 h-12 rounded-full text-gray-300"></BiUserCircle>
            )}
            {data?.[0]?.firstName && (
              <p className="text-lg md:text-xl font-semibold text-gray-100">
                {data?.[0]?.firstName}
              </p>
            )}
            {data?.[0]?.institute && (
              <p className="text-base md:text-lg font-semibold text-gray-100">
                To: {data?.[0]?.institute}
              </p>
            )}
            {data?.[0]?.subject && (
              <p className="text-base md:text-lg font-semibold text-gray-100">
                Subject: {data?.[0]?.subject}
              </p>
            )}

            {data?.[0]?.officeName && (
              <p className="text-base md:text-lg font-semibold text-gray-100">
                To: {data?.[0]?.officeName}
              </p>
            )}

            {data?.[0]?.inquiry && (
              <p className=" text-gray-200 text-sm font-semibold">
                Inquiry: {data?.[0]?.inquiry}
              </p>
            )}

            {data?.[0]?.service && (
              <p className="text-gray-200 text-sm font-semibold">
                Service Type: {data?.[0]?.service}
              </p>
            )}
            {data?.[0]?.time && (
              <p className="text-gray-200 text-sm font-semibold">
                Date: {new Date(data?.[0]?.time).toLocaleString()}
              </p>
            )}
            {data?.[0]?.email && (
              <a
                href={`mailto:${data?.[0]?.email}`}
                className=" text-gray-200 text-sm font-semibold "
              >
                Email:{" "}
                <span className="hover:text-white">{data?.[0]?.email}</span>
              </a>
            )}

            {data?.[0]?.phone && (
              <a
                href={`tel:+1${data?.[0]?.phone}`}
                className=" text-gray-200 text-sm font-semibold "
              >
                Phone:{" "}
                <span className="hover:text-white">{data?.[0]?.phone}</span>
              </a>
            )}
          </div>
        )}

        <div
          // id='chat-feed'
          className="px-6  h-[67vh] overflow-y-scroll"
          ref={chatDivRef}
        >
          {data?.map((message) => (
            <div key={message?._id} className="py-2.5 ">
              {!message?.role && (
                <p className="flex items-center gap-3">
                  {message?.photoURL && (
                    <img
                      src={message?.photoURL}
                      alt={`${message?.photoURL}`}
                      className="w-6 h-6 rounded-full"
                      w='24'
                      h='24'
                    />
                  )}
                  {!message?.photoURL && (
                    <BiUserCircle className="w-6 h-6 rounded-full text-gray-400"></BiUserCircle>
                  )}
                  <span
                    className="bg-gray-400 text-white px-3 py-1.5 rounded-md shadow-md  "
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {" "}
                    <Linkify componentDecorator={customComponentDecorator}>
                      {message?.messages}
                    </Linkify>{" "}
                  </span>
                </p>
              )}
              {message?.role && (
                <h1
                  className="flex items-center gap-3 justify-end"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <span className="bg-primary text-white px-3 py-1.5 rounded-md shadow-md ">
                    {" "}
                    <Linkify componentDecorator={customComponentDecorator}>
                      {message?.messages}
                    </Linkify>{" "}
                  </span>
                  <img
                    src={message?.photoURL}
                    alt={`${message?.photoURL}`}
                    className="w-6 h-6 rounded-full"
                    w='24'
                    h='24'
                  />
                </h1>
              )}
            </div>
          ))}
        </div>

        <div>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className=" lg:w-[90%] w-[92%] mx-auto   relative ">
              <textarea
                type="text"
                {...register("message", {
                  required: "message is required",
                })}
                // name="subject"
                id="message"
                rows={2}
                placeholder="Type Your Message"
                className="w-full px-4 py-3 border pr-16 rounded-md text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                              focus:bg-white shadow-md dark:bg-gray-400 dark:placeholder:text-gray-100 dark:text-gray-100"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent the default action to avoid new line
                    handleSubmit(submitHandler)(); // Manually trigger form submit
                  }
                }}
              />

              <button type="submit">
                <IoMdArrowRoundUp className=" bg-black p-1 absolute right-4 bottom-6   text-white text-4xl rounded-md"></IoMdArrowRoundUp>
              </button>
            </div>
            {errors.message && (
              <p className="text-red-600 lg:w-[90%] w-[92%] mx-auto ">
                {errors.message.message}
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminConversation;

import axios from 'axios'
import React from 'react'
import { TbFileSearch } from "react-icons/tb";
import { useQuery } from '@tanstack/react-query'
import { NavLink } from 'react-router-dom'

const AllFormData = () => {

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["allChat"],
        queryFn: async () => {
          const { data } = await axios.get(
            `https://cottage-updated-server-v3.vercel.app/helpDesk`
          );
          return data;
        },
        refetchInterval: 2000,
      });
      
    // console.log(data)

    const requests = {};

// Iterate through the database array
data?.forEach(request => {
  const type = request.type;
  const review = request.review;

  // Initialize the type object if it doesn't exist
  if (!requests[type]) {
    requests[type] = {
      length: 0,
      review: 0
    };
  }

  // Increment the length for each type
  requests[type].length++;

  // Increment the review count if it's true
  if (review === "true") {
    requests[type].review++;
  }
});

// console.log(requests);


    return (
        <div>
            <div>
                <li className="my-1 dark:text-gray-100 text-lg text-center ">

                    <NavLink to="/dashboard/generalRequest" className="flex items-center gap-3">
                        <TbFileSearch className="md:text-2xl" />
                        {requests?.generalRequest?.length >0 &&
                        <sup className="text-sm font-semibold text-red-500 -ml-3">
                        {requests?.generalRequest?.length - requests?.generalRequest?.review }</sup>


                        }
                          All General Request
                    </NavLink>

                </li>
                <li className="my-1 dark:text-gray-100 text-lg text-center ">
                    <NavLink to="/dashboard/payStub" className="flex items-center gap-3">

                        <TbFileSearch className="md:text-2xl" />
                        {
                            requests?.payStub?.length > 0 &&
                            <sup className="text-sm font-semibold text-red-500 -ml-3">
                            {requests?.payStub?.length - requests?.payStub?.review }</sup> 

                        }
                        
                       First Time Pay Stub

                    </NavLink>
                </li>
                <li className="my-1 dark:text-gray-100 text-lg text-center ">
                    <NavLink to="/dashboard/payroll" className="flex items-center gap-3">

                        <TbFileSearch className="md:text-2xl"/> 
                        {
                            requests?.payroll?.length > 0 &&
                            <sup className="text-sm font-semibold text-red-500 -ml-3">
                            {requests?.payroll?.length - requests?.payroll?.review }</sup>
                        }
                        
                         PayRoll Inquiries

                    </NavLink>
                </li>
                <li className="my-1 dark:text-gray-100 text-lg text-center ">
                    <NavLink to="/dashboard/supplies" className="flex items-center gap-3">

                        <TbFileSearch className="md:text-2xl" /> 
                        {
                            requests?.requestSupply?.length > 0 &&
                            <sup className="text-sm font-semibold text-red-500 -ml-3">
                            {requests?.requestSupply?.length - requests?.requestSupply?.review }</sup>

                        }
                         Request Supplies

                    </NavLink>
                </li>
                <li className="my-1  text-lg text-center dark:text-gray-100">
                    <NavLink to="/dashboard/pto" className="flex items-center gap-3 ">

                        <TbFileSearch className="md:text-2xl" />
                        {
                            requests?.requestPto?.length > 0 &&
                            <sup className="text-sm font-semibold text-red-500 -ml-3">
                            {requests?.requestPto?.length - requests?.requestPto?.review }</sup>

                        }
                        Request to Use Pto

                    </NavLink>
                </li>
            </div>

        </div>
    )
}

export default AllFormData

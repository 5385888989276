import React, { Suspense } from "react";
import ContactForm from "../ContactInfo/ContactForm";
import ContactInfo from "../ContactInfo/ContactInfo";
import OfficeLocation from "../OfficeLocation/OfficeLocation";
import Chat from "../../../Shared/Chat/Chat";
import SkeletonLoading from "../../../Shared/Loading/SkeletonLoading";
import PageComponent from "../../../PageComponent/PageComponent";

const Placeholder = () => <div><SkeletonLoading/></div>;
const LazyBanner = React.lazy(() => import('../../Home/Care/Care'));

const Contacts = () => {
  
  return (
    <div className="dark:bg-slate-600">
      <PageComponent
        title=" Contact Us - Cottage Home Care Services"
        description="Get in touch with Cottage Home Care Services. Call us at 516-367-2266 or email info@cottagehomecare.com. Visit us at our offices in South Richmond Hill Queens, Hicksville, Jamaica, and Washington. We are here to provide support and answer any questions you may have about our home care services."
        keywords="contact Cottage Home Care, home care services contact, South Richmond Hill Queens, Hicksville office, Jamaica office, Washington office, home care support, Cottage Home Care Services contact, 516-367-2266, info@cottagehomecare.com"
      />
      <Suspense fallback={<Placeholder />}>
        {/* Lazy-loaded banner */}
        <LazyBanner />
      </Suspense>
      <ContactInfo>
      </ContactInfo>
      <ContactForm>
      </ContactForm>
      <OfficeLocation>   
      </OfficeLocation>
      <Chat></Chat>
     
    </div>
  );
};

export default Contacts;

import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useQuery } from '@tanstack/react-query';
import RegisterModal from "../Registerusers/RegisterModal";
import Loading from "../../Shared/Loading/Loading";
import { Link } from "react-router-dom";
import slugify from 'slugify'; // Importing slugify

const PendingBlogs = () => {
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(7);

  const url = `https://cottage-updated-server-v3.vercel.app/blogs?page=${page}&size=${size}`;

  const {
    data: { blogs, count } = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["generalRequest", page, size],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  const pages = Math.ceil(count / size);

  if (isLoading) {
    return <Loading />;
  }

  const rejectHandler = (blog) => {
    fetch(`https://cottage-updated-server-v3.vercel.app/blogs/reject/${blog._id}`, {
      method: "PUT",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success("Reject Successful");
          refetch();
        }
      });
  };

  const approveHandler = (blog) => {
    fetch(`https://cottage-updated-server-v3.vercel.app/blogs/approve/${blog._id}`, {
      method: "PUT",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success("Approve Successful");
          refetch();
        }
      });
  };

  const apiKey = process.env.REACT_APP_secureApiKey;

  const deleteHandler = (blog) => {
    const proceed = window.confirm(
      `Are you sure, you want to delete ${blog?.title} ?`
    );
    if (proceed) {
      fetch(`https://cottage-updated-server-v3.vercel.app/blogs/${blog?._id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': `${apiKey}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            toast.success("Blog Delete Successfully");
            refetch();
          }
        });
    }
  };

  return (
    <div className="border-t-2">
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead className="">
            <tr>
              <th className="dark:bg-slate-800 dark:text-gray-200">Serial</th>
              <th className="dark:bg-slate-800 dark:text-gray-200">Blog Name</th>
              <th className="dark:bg-slate-800 dark:text-gray-200">Date & Time</th>
              <th className="dark:bg-slate-800 dark:text-gray-200">Status</th>
              <th className="dark:bg-slate-800 dark:text-gray-200">Preview</th>
              <th className="dark:bg-slate-800 dark:text-gray-200">Delete</th>
            </tr>
          </thead>
          <tbody>
            {blogs?.map((blog, index) => {
              const slug = slugify(blog.title, { lower: true, strict: true });
              return (
                <tr className="hover" key={blog._id}>
                  <th className="dark:bg-slate-500 dark:text-gray-100">
                    {index + 1}
                  </th>
                  <td className="dark:bg-slate-500 dark:text-gray-100">
                    {blog?.title}
                  </td>
                  <td className="dark:bg-slate-500 dark:text-gray-100">
                    <p className="text-primary dark:text-gray-300 text-sm font-medium">
                      {new Date(blog?.newDate).toLocaleString()}
                    </p>
                  </td>
                  <td className="dark:bg-slate-500 dark:text-gray-100 cursor-pointer">
                    {blog?.status === "pending" && (
                      <div
                        className="form-control"
                        onClick={() => approveHandler(blog)}
                      >
                        <label className="cursor-pointer label block">
                          <p className=" flex items-center gap-2 text-warning">
                            <input
                              type="checkbox"
                              checked="checked"
                              className="checkbox checkbox-warning"
                            />
                            <span>Pending</span>
                          </p>
                        </label>
                      </div>
                    )}
                    {blog?.status === "rejected" && (
                      <div
                        className="form-control"
                        onClick={() => approveHandler(blog)}
                      >
                        <label className="cursor-pointer label block">
                          <p className=" flex items-center gap-2 text-warning">
                            <input
                              type="checkbox"
                              checked="checked"
                              className="checkbox checkbox-warning"
                            />
                            <span>Rejected</span>
                          </p>
                        </label>
                      </div>
                    )}
                    {blog?.status === "approved" && (
                      <div
                        className="form-control"
                        onClick={() => rejectHandler(blog)}
                      >
                        <label className="cursor-pointer label block ">
                          <p className="flex items-center gap-2 text-primary">
                            <input
                              type="checkbox"
                              checked="checked"
                              className="checkbox checkbox-primary"
                            />
                            <span>Approved</span>
                          </p>
                        </label>
                      </div>
                    )}
                    {!blog?.status && (
                      <span className="tag tag-admin text-primary dark:text-green-400">Posted by Admin</span>
                    )}
                  </td>
                  <td className="dark:bg-slate-500 dark:text-gray-100">
                    <Link
                      to={`/${slug}`}
                      className="btn btn-sm bg-primary uppercase text-white"
                    >
                      Preview
                    </Link>
                  </td>
                  <td className="dark:bg-slate-500 dark:text-gray-100">
                    <button
                      onClick={() => deleteHandler(blog)}
                      className="btn btn-sm bg-red-600 uppercase text-white"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {<RegisterModal message={message} />}
      </div>
      <div>
        <p className="text-center mt-10 text-lg font-semibold dark:text-gray-100">
          Currently Selected page:{" "}
          <span className="text-primary">{page + 1}</span>
        </p>
        <div className="pagination my-3 flex justify-center">
          {[...Array(pages).keys()].map((number) => (
            <button
              key={number}
              className={
                page === number
                  ? "selected px-3 py-1 text-white ml-3 cursor-pointer custom-shadow"
                  : "px-3 py-1 text-gray-500 ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-[#444444] hover:text-white custom-shadow"
              }
              onClick={() => setPage(number)}
            >
              {number + 1}
            </button>
          ))}

          <select
            className="ml-3 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-2"
            onChange={(event) => setSize(event.target.value)}
          >
            <option
              selected
              disabled
              className="hidden"
            >{`Page Size ${size}`}</option>

            <option value="7">Page Size 7</option>
            <option value="10">Page Size 10</option>
            <option value="15">Page Size 15</option>
            <option value="20">Page Size 20</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default PendingBlogs;

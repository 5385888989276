import React, { Suspense } from "react";
import Stressed from "../Stressed/Stressed";
import Carousel from "./Carousel";
import ServiceCard from "./ServiceCard";
import Chat from "../../../Shared/Chat/Chat";
import SkeletonLoading from "../../../Shared/Loading/SkeletonLoading";
import PageComponent from "../../../PageComponent/PageComponent";

const Placeholder = () => (
  <div>
    <SkeletonLoading />
  </div>
);
const LazyBanner = React.lazy(() =>
  import("../../../../Components/Pages/Services/Banner/ServiceBanner")
);

const Services = () => {
  
 

  return (
    <div className="dark:bg-slate-600">
      <PageComponent
        title=" Services - Cottage Home Care Services"
        description="HHA/PCA & CDPAP SERVICES THROUGHOUT NEW YORK CITY, NASSAU & SUFFOLK COUNTY, WESTCHESTER & ALBANY"
        keywords="home care services, HHA, PCA, CDPAP, New York City, Nassau, Suffolk County, Westchester, Albany, senior care, healthcare services"
      />

      <div>
        <Suspense fallback={<Placeholder />}>
          {/* Lazy-loaded banner */}
          <LazyBanner />
        </Suspense>
      </div>
      <ServiceCard></ServiceCard>

      <Stressed></Stressed>

      <div className="bg-[#EBF8F9] py-20 dark:bg-slate-600">
        <h1 className="text-[#322e51] pb-2 text-2xl lg:text-[35px] relative font-bold advertise afterEffect md:mb-10 mb-5 text-center px-5 dark:text-gray-100">
          " The Right Home Care for You"
        </h1>
        <div className="w-[95%] lg:w-[75%] mx-auto">
          <Carousel>

            
          </Carousel>
        </div>
      </div>
      {/* <Care></Care> */}
      <Chat></Chat>
    </div>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useLoaderData, useNavigate } from 'react-router-dom';
import OverlayLoading from '../OverlayLoading/OverlayLoading';

const EditBlogForm = () => {
    const blog = useLoaderData();

    const [loading, setLoading] = useState(false);
    const [paragraphs, setParagraphs] = useState([]);
    const [widgetLoaded, setWidgetLoaded] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

    useEffect(() => {
        // Initialize paragraphs from the blog data
        setParagraphs(blog?.description || []);

        // Load the Cloudinary widget
        const loadCloudinaryWidget = () => {
            const script = document.createElement('script');
            script.src = 'https://widget.cloudinary.com/v2.0/global/all.js';
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                setWidgetLoaded(true);
            };
        };

        loadCloudinaryWidget();
    }, [blog]);

    const addParagraph = () => {
        setParagraphs([...paragraphs, { sub_title: '', content: '' }]);
    };

    const removeParagraph = (index) => {
        setParagraphs(paragraphs.filter((_, i) => i !== index));
    };

    const apiKey = process.env.REACT_APP_secureApiKey;

    const handleImageUpload = () => {
        if (widgetLoaded && window.cloudinary) {
            window.cloudinary.openUploadWidget(
                { 
                    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
                    uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
                    sources: ['local', 'url'],
                    multiple: false
                },
                (error, result) => {
                    if (!error && result && result.event === "success") {
                        setValue('imageUrl', result.info.secure_url, { shouldValidate: true });
                        toast.success('Image uploaded successfully.');
                    } else if (error) {
                        toast.error('Image upload failed. Please try again.');
                    }
                }
            );
        } else {
            toast.error('Cloudinary widget is not loaded yet. Please try again.');
        }
    };

    const submitHandler = (data) => {
        setLoading(true);
        const imageUrl = data?.imageUrl ? data.imageUrl : blog?.img;
        updateBlog(data, imageUrl);
    };

    const updateBlog = (data, imgUrl) => {
        const updatedDescription = paragraphs.map((_, index) => ({
            sub_title: data[`title${index + 1}`],
            content: data[`description${index + 1}`].replace(/\n/g, '<br/>'),
        }));

        const updateBlog = {
            _id: blog?._id,
            title: data?.title,
            img: imgUrl,
            description: updatedDescription,
        };

        fetch(`https://cottage-updated-server-v3.vercel.app/blogs`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                'X-API-Key': `${apiKey}`,
            },
            body: JSON.stringify(updateBlog),
        })
        .then((res) => res.json())
        .then((data) => {
            setLoading(false);
            if (data.acknowledged) {
                toast.success("Blog Updated Successfully");
                reset();
                navigate('/blog');
            } else {
                toast.error("Failed to update the blog. Please try again.");
            }
        })
        .catch((error) => {
            setLoading(false);
            toast.error("An error occurred. Please try again.");
            console.error(error);
        });
    };

    return (
        <div className="pt-5 contact-bg" id="contact-form">
            {loading && 
                <div className='my-5'>
                    <OverlayLoading/>
                </div>
            }
            <section className="flex justify-center items-center pb-8 mt-3 mb-12 rounded w-11/12 md:w-[80%] mx-auto">
                <div className="w-full" data-aos="fade-up" data-aos-duration="2000">
                    <form onSubmit={handleSubmit(submitHandler)} className="max-w-xl md:max-w-3xl ml-auto bg-gray-50 p-8 rounded-md shadow-xl overflow-y-scroll">
                        <h1 className="text-xl text-gray-600 text-center Poppins font-semibold">{blog?.title}</h1>

                        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                            <div className="space-y-0.5 text-sm">
                                <label htmlFor="title" className="block dark:text-gray-400">Title</label>
                                <input
                                    type="text"
                                    defaultValue={blog?.title}
                                    {...register("title", { required: "Title is required" })}
                                    id="title"
                                    placeholder="Title"
                                    className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9] focus:bg-white shadow-md"
                                />
                                {errors.title && <p className="text-red-600">{errors.title.message}</p>}
                            </div>

                            <div className="space-y-0.5 text-sm">
                                <div className='flex items-center gap-4'>
                                    <label htmlFor="imageUrl" className="block dark:text-gray-400">Image</label>
                                </div>
                                <button
                                    type="button"
                                    onClick={handleImageUpload}
                                    className="file-input file-input-secondary border-0 w-full text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9] focus:bg-white shadow-md"
                                >
                                    Upload Image
                                </button>
                                {errors.imageUrl && <p className='text-red-600'>{errors.imageUrl.message}</p>}
                            </div>
                        </div>

                        <hr className="my-3" />

                        {paragraphs?.map((paragraph, index) => (
                            <div key={index} className="mb-4">
                                <div className="space-y-0.5 text-sm">
                                    <input
                                        type="text"
                                        defaultValue={paragraph?.sub_title}
                                        {...register(`title${index + 1}`)}
                                        id={`title${index + 1}`}
                                        placeholder={`Paragraph Title-${index + 1}`}
                                        className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9] focus:bg-white shadow-md"
                                    />
                                    {errors[`title${index + 1}`] && <p className="text-red-600">{errors[`title${index + 1}`].message}</p>}
                                </div>

                                <div className="space-y-0.5 text-sm mt-5">
                                    <textarea
                                        defaultValue={paragraph?.content}
                                        {...register(`description${index + 1}`, { required: "Description is required" })}
                                        id={`description${index + 1}`}
                                        rows={5}
                                        placeholder={`Paragraph-${index + 1} Description`}
                                        className="w-full px-4 py-3 border rounded-md text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9] focus:bg-white shadow-md"
                                    />
                                    {errors[`description${index + 1}`] && <p className="text-red-600">{errors[`description${index + 1}`].message}</p>}
                                </div>

                                <button type="button" onClick={() => removeParagraph(index)} className="mt-2 bg-red-500 text-white py-1 px-3 rounded">Remove</button>
                            </div>
                        ))}

                        <button type="button" onClick={addParagraph} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Add Paragraph</button>

                        <button type="submit" className="block w-full p-3 text-center rounded-sm bg-primary hover:bg-secondary text-white mt-6">Update</button>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default EditBlogForm;

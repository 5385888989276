import React, { useState } from "react";
import BlogCard from "./BlogCard";
import SingleBlog from "./SingleBlog";
import NewLoading from "../NewLoading";

const AllBlog = ({ blogs, isLoading, isAdmin, refetch, isEditor }) => {


 
  return (
    <div className="pt-5 pb-20 bg-[#DBF2F4] dark:bg-slate-600">
      <h1 className="text-4xl advertise text-center font-semibold mt-8 dark:text-gray-100">
        Cottage Care Blog{" "}
      </h1>
      <hr className="mt-3 border-t-[1px] border-primary w-16 mx-auto " />

      <div className="blog-section md:w-[90%] w-[90%] mx-auto mt-8">
        {isLoading && <NewLoading />}
      
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {blogs
            ?.filter((blog) => blog?.status !== "pending" && blog?.status !== "rejected")
            .map((blog) => (
              <BlogCard
                key={blog?._id}
                blog={blog}
                isAdmin={isAdmin}
                isEditor={isEditor}
                refetch={refetch}
                
              />
            ))}
            
        </div>
      </div>
    </div>
  );
};

export default AllBlog;

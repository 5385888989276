import React from "react";
import articleIMG from "../../../assets/articels-picture.webp";
import fahima from "../../../assets/fahima.webp";
import love from "../../../assets/love.png";
import home from "../../../assets/home.png";
import "./Benifits.css";
import { Link } from "react-router-dom";
import MyImageComponent from "../../Utils/MyImageComponent";

const Benefits = () => {
  return (
    <div className="w-[95%] lg:w-[75%] mx-auto md:my-12 -mt-5">
      <div>
        <div className="md:grid grid-cols-5  gap-10 ">
          <div
            className="col-span-3 mx-auto md:flex items-end gap-10  "
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <div className="w-[95%] mx-auto md:w-full ">
             
              <MyImageComponent
                src={articleIMG}
                alt="care-giver"
                className="lg:h-[440px] w-full shadow-md h-74 care-img"
                width="1080"
                height="720"
              />
            </div>

            <div>
              <MyImageComponent
                src={love}
                alt="love"
                className="shadow-md hidden md:block"
                width="102"
                height="102"
              />
            </div>
          </div>
          <div
            className="col-span-2 w-[95%] md:w-full mx-auto mt-5 md:mt-0"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <div>
              <h1 className="text-xl md:text-2xl lg:text-4xl text-primary font-semibold dark:text-gray-200 advertise">
                Home Health Aides
              </h1>
              <h1 className="text-xl md:text-2xl lg:text-4xl text-primary font-semibold mt-1 dark:text-gray-200 advertise">
                That Care
              </h1>
              <p className="md:text-lg mt-3 text-justify dark:text-gray-200 text-gray-600 ">
                Our caregivers are selected for their compassion, experience and
                superior know-how. Our staff will lovingly place you with the
                caregiver that suits your needs. Now you can be rest assured
                knowing your loved one is in safe, capable hands.
              </p>
              <Link to="/contacts">
                <div className="flex items-center gap-2 mt-5 md:mt-10">
                  <p className="bg-primary py-[1.2px] w-12"></p>
                  <p className="text-lg font-medium text-gray-500 dark:text-gray-200">
                    Get Care
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[40px]">
        <div className="md:grid grid-cols-6  gap-10 items-center  ">
          <div
            className=" col-span-2 w-[95%] md:w-full mx-auto"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <div className="">
              <h1 className="text-xl md:text-2xl lg:text-4xl text-primary font-semibold dark:text-gray-200 advertise">
                Personalized care plans
              </h1>

              <p className="md:text-lg mt-3 text-justify dark:text-gray-200 text-gray-600">
                We know everyone’s level of care can vary. At Cottage Home Care,
                our team of registered nurses and care coordinators work
                together with you to create an individualized plan for you or
                your loved one, using all available resources to ensure you
                receive the care you need.
              </p>
              <Link to="/contacts">
                <div className="flex items-center gap-2 mt-5 md:mt-10">
                  <p className="bg-primary py-[1.2px] w-12"></p>
                  <p className="text-lg font-medium text-gray-500 dark:text-gray-200">
                    Get Care
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-span-4   mt-5 md:mt-0 "
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="md:flex items-start gap-10 md:w-[95%]  ml-auto">
              <div>
                <MyImageComponent
                  src={home}
                  alt="home"
                  className="shadow-md hidden md:block"
                  width="151"
                  height="151"
                />
              </div>

              <div className="md:w-full  w-[95%] mx-auto ">
                <MyImageComponent
                  src={fahima}
                  alt="care-giver"
                  className="lg:h-[500px] shadow-md w-full ml-auto  h-74 care-img"
                  width="1080"
                  height="902"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;

import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../Shared/Footer/Footer";
import NavBar from "../NavBar/NavBar";
import TopBar from "../TopBar/TopBar";
import PopUp from "../../PopUp";
import whatsAppIcon from "../../../assets/Icon/whatsapp.webp";
import { AuthContext } from "../Context/AuthProvider";
import useAdmin from "../../Hooks/UseAdmin";
import ToastNotification from "../Home/Home/ToastNotification";
import MyImageComponent from "../../Utils/MyImageComponent";

const Main = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [property, setShowProperty] = useState("");

  const { user } = useContext(AuthContext);

  const [isAdmin] = useAdmin(user?.email);

  useEffect(() => {
    if (pathname === "/conversation" || pathname === "/videos") {
      setShowProperty("hidden");
    } else {
      setShowProperty("");
    }
  }, [pathname]);

  return (
    <div>
      <div className={`${pathname === "/videos" ? "hidden" : "block"} `}>
        <TopBar></TopBar>
      </div>
      <NavBar></NavBar>

      <PopUp />
      <div className="overflow-hidden">
        <Outlet></Outlet>
      </div>
      <div
        className={`${property} md:w-[60px] md:h-[60px] w-11 h-11 z-40 big-display cursor-pointer fixed bottom-[78px] md:bottom-[104px] right-2.5 md:right-6`}
      >
        <a
          href="https://wa.me/15163672266?text=Hello%20there!"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center text-center md:text-7xl text-primary"
        >
          <MyImageComponent
            src={whatsAppIcon}
            className="w-full h-full"
            alt=""
            width="60"
            height="60"
          />
        </a>
      </div>

      <Footer></Footer>

      {user?.uid && (
        <>
          {isAdmin && (
            <>
              <ToastNotification />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Main;

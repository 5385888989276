import React from 'react'
import NewSlider from './NewSlider'


const UpdateTestimonial = () => {
  return (
    <div className=''>

          <div className='my-10'>
              <h3 className='text-center lobster text-2xl text-primary md:text-3xl dark:text-gray-100'>Testimonials From Our Patients and CareGivers</h3>
              <div className="flex justify-center">
                  <hr className="bg-primary px-5 mt-2 border-t-[3px] border-primary w-[80px] lg:w-[180px] mb-5" />
              </div>
              <div className="bg-gradient-to-t from-[#00837f91] to-[#2044afbd]  dark:from-slate-200 dark:to-slate-400 min-h-[420px] md:min-h-fit 
             p-1 rounded-md w-[83%] md:w-[80%] mx-auto mb-10  lg:mb-20 relative " id='carousel-section'>


                  <div className=''>
                      <NewSlider/>
                  </div>

              </div>
          </div>
   
    </div>
  )
}

export default UpdateTestimonial

import React, { useEffect } from "react";
import { Link, useLoaderData } from "react-router-dom";
import QRCode from "qrcode.react";
import Linkify from "react-linkify";
import "./EmployeeDetails.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaInstagramSquare } from "react-icons/fa";

const EmployeeDetails = () => {
  

  const employee = useLoaderData();
  console.log(employee);

  const currentPageUrl = window.location.href;

  const customComponentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      style={{ color: "blue" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  return (
    <div className="min-h-screen dark:bg-slate-700">
      <div className="pt-[5vh]"></div>

      <div
        id="employee-details"
        className="bg-slate-100 md:bg-white w-[95%] md:max-w-4xl lg:max-w-5xl md:min-h-[70vh] mx-auto shadow-2xl rounded-xl
             relative dark:bg-slate-500"
      >
        <div className="p-4 md:p-8 ">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 text-gray-600">
              <div>
                <PhotoProvider>
                  <PhotoView src={employee?.img}>
                    <img
                      className="w-[55px] h-[55px] md:w-20 md:h-20 rounded-full shadow-md cursor-pointer"
                      src={employee?.img}
                      alt={employee?.name}
                      width='55'
                      height='55'
                    />
                  </PhotoView>
                </PhotoProvider>
              </div>
              <div className="open-sans">
                <h1 className=" font-bold text-base md:text-[22px] dark:text-white">
                  {employee?.name}
                </h1>
                <h5 className="mt-1 md:block hidden">
                  <span className="font-semibold dark:text-white">
                    {employee?.designation}
                  </span>
                  <span className="mx-1 font-semibold text-gray-500 dark:text-gray-200">
                    at
                  </span>
                  <span className="font-semibold dark:text-white">
                    {" "}
                    Cottage Home Care Services
                  </span>
                </h5>
                {employee?._id === "65dcede2331dfddc25c80c26" ? (
                  <>
                    <h5 className="space-y-0.5 block md:hidden dark:text-gray-100">
                      <p className="text-sm font-semibold">
                        {" "}
                        Chief Operating Officer{" "}
                      </p>
                      <p className="text-xs font-semibold">
                        At Cottage Home Care Services
                      </p>
                    </h5>
                  </>
                ) : (
                  <>
                    <h5 className="space-y-0.5 block md:hidden dark:text-gray-100">
                      <p className="text-sm font-semibold">
                        {employee?.designation}
                      </p>
                      <p className="text-xs font-semibold">
                        At Cottage Home Care Services
                      </p>
                    </h5>
                  </>
                )}
              </div>
            </div>
            <div>
              <Link
                to="/team"
                className=" px-2 py-2.5 md:px-4 md:py-3 bg-black text-white rounded-lg shadow lg:mr-10 text-[10px] md:text-base"
              >
                All Employees
              </Link>
            </div>
          </div>

          <div className="md:mt-5 mt-3 ">
            {employee?.primaryNumber && (
              <p className="text-gray-600 dark:text-gray-100 text-sm md:text-base">
                <b>Primary Number:</b>{" "}
                <a href={`tel:+1${employee?.primaryNumber}`}>
                  {employee?.primaryNumber}
                </a>
              </p>
            )}

            {employee?.directNumber && (
              <p className="text-gray-600 dark:text-gray-100 text-sm md:text-base">
                {" "}
                <b>Cell:</b>{" "}
                <a href={`tel:+1${employee?.directNumber}`}>
                  {employee?.directNumber}
                </a>{" "}
              </p>
            )}
            {employee?.extension && (
              <p className="text-gray-600 dark:text-gray-100 text-sm md:text-base">
                {" "}
                <b>Extension: </b>{" "}
                <a href={`tel:+1${employee?.extension}`}>
                  {employee?.extension}
                </a>{" "}
              </p>
            )}
            {employee?.email && (
              <p className="text-gray-600 dark:text-gray-100 text-sm md:text-base">
                {" "}
                <b>Email:</b>{" "}
                <a href={`mailto:${employee?.email}`}>{employee?.email}</a>
              </p>
            )}
          </div>

          <Linkify componentDecorator={customComponentDecorator}>
            <p
              style={{ whiteSpace: "pre-line" }}
              className="text-justify mt-3 md:mt-3 pb-10 dark:text-gray-100 text-[#6c6262] text-sm md:text-base"
            >
              {employee?.description}
            </p>
          </Linkify>

          <div className="flex items-center justify-between md:block">
            <div className="flex gap-3 text-2xl lg:text-3xl md:absolute bottom-8 left-14  dark:text-gray-100">
              {employee?.facebook ? (
                <>
                  <a href={employee?.facebook} target="_blank">
                    <FaSquareFacebook className='bg-hov2' />
                  </a>
                </>
              ) : (
                <>
                  <a href="#">
                    <FaSquareFacebook className='bg-hov2' />
                  </a>
                </>
              )}
              {employee?.instagram ? (
                <>
                  <a href={employee?.instagram} target="_blank" rel="noopener noreferrer">
                    <FaInstagramSquare className='bg-hov2' />
                  </a>
                </>
              ) : (
                <>
                  <a href="#">
                    <FaInstagramSquare className='bg-hov2' />
                  </a>
                </>
              )}

              {employee?.twitter ? (
                <>
                  <a href={employee?.twitter} target="_blank" rel="noopener noreferrer">
                    <FaXTwitter className='bg-hov2' />
                  </a>
                </>
              ) : (
                <>
                  <a href="#">
                    <FaXTwitter className='bg-hov2' />
                  </a>
                </>
              )}
                {
                  employee?.linkEdin ?
                  <>
                  <a href={employee?.linkEdin} target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="bg-hov2" />
              </a>
                  </>
                  :
                  <>
                  <a href="#">
                <FaLinkedin className='bg-hov2' />
              </a>
                  </>

                }
              

              {employee?.web && (
                <>
                  <a href={employee?.web} target="_blank" rel="noopener noreferrer">
                    <AiOutlineGlobal className='bg-hov2' />
                  </a>
                </>
              )}
            </div>
            <div className="md:absolute bottom-8 right-14   my-4 md:my-0 ">
              <QRCode className="w-10" value={currentPageUrl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;

import React, { useContext, useEffect, useState } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { FaFilePdf, FaUserTie } from "react-icons/fa";
import { ImBlog } from "react-icons/im";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { SiGooglemessages } from "react-icons/si";
import { Link, NavLink, Outlet } from "react-router-dom";
import useTitle from "../Components/Hooks/useTitle";
import { AuthContext } from "../Components/Pages/Context/AuthProvider";
import NavBar from "../Components/Pages/NavBar/NavBar";
import Footer from "../Components/Shared/Footer/Footer";
import notificationSound from "../../src/assets/Notification-sound/Messenger_Facebook.mp3";
import toast from "react-hot-toast";
import AllFormData from "./AllFormData";

const DashBoardLayout = () => {
  useTitle("Dashboard");
  const { user } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [storageMessage, setStorageMessage] = useState(false);
  const [lastMessageCount, setLastMessageCount] = useState(() => {
    return JSON.parse(localStorage.getItem("lastMessageCount")) || {};
  });

  const playNotificationSound = () => {
    new Audio(notificationSound).play();
  };

  const totalDashMessage = localStorage.getItem("dashBoardMessage");
  const clearStorage = () => {
    localStorage.setItem("dashBoardMessage", 0);
    setStorageMessage(true);
  };

  useEffect(() => {
    fetch("https://cottage-updated-server-v3.vercel.app/chats", {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setChats(data.chats);
        const newMessageCount = {};

        data?.chats?.forEach((chat) => {
          if (chat?.messages && !chat?.role) {
            newMessageCount[chat.userId] =
              (newMessageCount[chat.userId] || 0) + 1;
          }
        });

        const totalNewMessages = Object.keys(newMessageCount).reduce(
          (total, userId) => {
            const lastCount = lastMessageCount[userId] || 0;
            const currentCount = newMessageCount[userId];
            return (
              total + (currentCount > lastCount ? currentCount - lastCount : 0)
            );
          },
          0
        );

        if (totalNewMessages > 0) {
          toast.success(`You have ${totalNewMessages} new messages`);
          localStorage.setItem("dashBoardMessage", totalNewMessages);
          playNotificationSound();
        }

        localStorage.setItem(
          "lastMessageCount",
          JSON.stringify(newMessageCount)
        );
        setLastMessageCount(newMessageCount);
      })
      .catch((error) => {
        console.error("Fetch error: ", error);
        toast.error("Failed to fetch chat data.");
      });

    return () => {
      setChats([]); // Clean up
    };
  }, [user, storageMessage, lastMessageCount]);

  return (
    <div className="min-h-screen">
      <div className="sticky top-0 z-50">
        <NavBar />
        <div className="py-3 block lg:hidden dark:bg-slate-600">
          <label
            onClick={() => setDrawer(!drawer)}
            htmlFor="dashboard-drawer"
            className="drawer-button text-4xl text-primary lg:hidden"
          >
            <CgMenuGridR />
          </label>
        </div>
      </div>

      <div className="drawer lg:drawer-open">
        <input
          id="dashboard-drawer"
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content dark:bg-slate-600">
          <Outlet />
        </div>
        <div
          className={`drawer-side ${
            drawer ? "block" : "hidden"
          } lg:border-2 dark:border-[#E5E7EB] w-[70%] lg:w-fit bg-white lg:bg-[#EBF8F9] lg:shadow-lg dark:bg-slate-600 dark:text-gray-100`}
        >
          <label
            htmlFor="dashboard-drawer"
            aria-label="close sidebar"
            className=" drawer-overlay "
          ></label>

          <ul className="menu p-4 w-80 text-base-content mt-5 ">
            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <NavLink to="/" className="flex items-center gap-4">
                <AiOutlineHome /> Home
              </NavLink>
            </li>
            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <Link to="/dashboard" className="flex items-center gap-4">
                <MdOutlineDashboard /> Dashboard
              </Link>
            </li>
            <li
              onClick={() => clearStorage()}
              className="my-1 dark:text-gray-100 text-lg text-center"
            >
              <NavLink
                to="/dashboard/users"
                className="flex items-center gap-4"
              >
                <SiGooglemessages />
                <sup className="text-sm font-semibold text-red-500 -ml-2">
                  {localStorage.getItem("dashBoardMessage") ? (
                    <span>{totalDashMessage} Message</span>
                  ) : (
                    <span>
                      {Object.keys(lastMessageCount).reduce(
                        (total, userId) =>
                          total + (lastMessageCount[userId] || 0),
                        0
                      )}{" "}
                      Messages
                    </span>
                  )}
                </sup>
              </NavLink>
            </li>
            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <NavLink
                to="/dashboard/employee"
                className="flex items-center gap-4"
              >
                <FaUserTie className="hover:text-primary" /> Add Employee
              </NavLink>
            </li>
            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <NavLink
                to="/dashboard/blogForm"
                className="flex items-center gap-4"
              >
                <ImBlog /> Add A Blog
              </NavLink>
            </li>
            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <NavLink
                to="/dashboard/pendingBlog"
                className="flex items-center gap-4"
              >
                <ImBlog /> Pending Blog
              </NavLink>
            </li>

            <AllFormData />

            <li className="my-1 dark:text-gray-100 text-lg text-center">
              <NavLink
                to="/dashboard/pdfForm"
                className="flex items-center gap-4"
              >
                <FaFilePdf /> Add A PDF Resource
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DashBoardLayout;

import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BiUserCircle } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useQuery } from '@tanstack/react-query';
import "../../../AllMessages/Pagination.css";
import AdminConversation from "../../../Chat/Conversation/AdminConversation";
import SkeletonLoading from "../../../Shared/Loading/SkeletonLoading";
import { Link } from "react-router-dom";
import NewLoading from "../../../NewLoading";

import { MdOutlineNavigateNext } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";

const AllUsers = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);

  const url = `https://cottage-updated-server-v3.vercel.app/users/activeUsers/active?page=${page}&size=${size}`;

  const apiKey = process.env.REACT_APP_secureApiKey

  const {
    data: { count, users } = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["activeUsers", page, size, load],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': `${apiKey}`,
        }
      });
      const data = await res.json();
      return data;
    },
  });

  const [newUser, setNewUser] = useState(users?.[0]);
  const [id, setId] = useState(users?.[0]?._id);

  const pages = Math.ceil(count / size);


  const nextPage = () => {
    setPage(prevPage => Math.min(prevPage + 1, pages - 1));
  };

  const prevPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 0));
  };

  const deleteHandler = (user) => {
    const proceed = window.confirm(
      `Are you sure, you want to delete ${user?.name} ?`
    );
    if (proceed) {
      fetch(
        `https://cottage-updated-server-v3.vercel.app/users/${user?._id}`,
        {
          method: "DELETE",
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': `${apiKey}`,
          }
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            toast.success("User Delete SuccessFully");
            refetch();
            localStorage.removeItem("createUser");
            
          }
        });
    }
  };

  const userHandler = (user) => {
    setNewUser(user);
    setId(user?._id);
    setShow(true);
  };

  return (
    <div className="min-h-screen md:border-2 bg-[#EBF8F9] dark:bg-slate-600 relative">

      <div className="md:grid grid-cols-7 hidden h-[88vh]">
        <div className="md:col-span-3 lg:col-span-2 md:border-r-4 h-[85vh] overflow-y-scroll ">
          {
            isLoading &&
            <div className="my-3">
              <NewLoading />
            </div>
          }
          {
            <ul className="menu p-4 w-[95%] mx-auto text-base ">
              {users?.map(
                (user) =>
                  user?.chat && (
                    <li key={user?._id} onClick={() => userHandler(user)}>
                      {user?._id === id ? (
                        <>
                          <div className="my-2 block bg-[#79A4A8] dark:bg-neutral-700 rounded-md text-white">
                            <div className="flex items-center justify-between ">
                              <div
                                //  to={`/dashboard/user/${user?._id}`}
                                className="flex items-center gap-3   w-full  px-2 "
                              >
                                {user?.photoURL && (
                                  <img
                                    src={user?.photoURL}
                                    alt={user?.photoURL}
                                    className=" w-8 2xl:w-10  h-8 2xl:h-10 border-2 border-primary rounded-full"
                                    w='32'
                                    h='32'
                                  />
                                )}
                                {!user?.photoURL && (
                                  <BiUserCircle className="w-8 h-8 2xl:w-12 2xl:h-12 rounded-full text-gray-300"></BiUserCircle>
                                )}

                                <p className="dark:text-gray-100 text-xs 2xl:text-base">
                                  {user?.name}
                                </p>
                              </div>

                              <div>
                                <details className="dropdown dropdown-end  ">
                                  <summary
                                    type="button"
                                    className="
                                        bg-transparent outline-none text-gray-200 dark:text-gray-100 text-2xl -mr mt-1 cursor-pointer"
                                        style={{ listStyle: "none" }}
                                  >
                                    <BsThreeDotsVertical></BsThreeDotsVertical>
                                  </summary>
                                  <ul className="px-4 py-6  menu dropdown-content z-[1] bg-gray-200 pr-10 rounded-md shadow-lg  dark:bg-slate-700 mt-3 duration-500">
                                    <li onClick={() => deleteHandler(user)}>
                                      <button className="text-sm w-full px-8 text-gray-500 hover:bg-primary btn-sm border-[1px] border-primary font-semibold hover:text-white dark:bg-slate-300 dark:hover:bg-primary">
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </details>
                              </div>
                            </div>
                            <div className="flex items-center justify-between text-gray-100 px-2 dark:text-gray-200">
                              {user?.adminMessage && (
                                <div>
                                  {user?.adminMessage?.length >= 16 ? (
                                    <>
                                      <p className="mt-1 text-xs 2xl:text-sm">
                                        You:{" "}
                                        {user?.adminMessage?.slice(0, 10) +
                                          "..."}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="mt-1 text-xs 2xl:text-sm">
                                        You: {user?.adminMessage}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}
                              {user?.message && (
                                <div className="mt-1">
                                  {user?.message?.length >= 18 ? (
                                    <>
                                      <p className=" text-xs 2xl:text-sm t">
                                        {user?.message?.slice(0, 16) + "..."}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className=" text-xs md:text-sm ">
                                        {user?.message}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}
                              {user?.time && (
                                <p className="text-xs ">
                                  {new Date(user?.time).toLocaleTimeString(
                                    "en-us",
                                    { month: "short", day: "numeric",hour: "numeric",
                                    minute: "numeric", }
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bg-[#D8E4E5] my-2 block dark:text-gray-100 dark:bg-slate-400">
                            <div className="flex items-center justify-between  ">
                              <h1
                                //  to={`/dashboard/user/${user?._id}`}
                                className="flex items-center gap-3   w-full  px-2 rounded-md"
                              >
                                {user?.photoURL && (
                                  <img
                                    src={user?.photoURL}
                                    alt={user?.photoURL}
                                    className=" w-8 h-8 2xl:w-10 2xl:h-10 border-2 border-primary rounded-full"
                                  
                                  />
                                )}
                                {!user?.photoURL && (
                                  <BiUserCircle className="w-8 h-8 2xl:w-12 2xl:h-12 rounded-full text-gray-300"></BiUserCircle>
                                )}

                                <p className="dark:text-gray-100 2xl:text-base text-xs">
                                  {user?.name}
                                </p>
                              </h1>

                              <div>
                                <details className="dropdown dropdown-end  ">
                                  <summary
                                    type="button"
                                    className="
                                        bg-transparent outline-none text-gray-500 dark:text-gray-100 text-2xl -mr mt-1 cursor-pointer"
                                        style={{ listStyle: "none" }}
                                  >
                                    <BsThreeDotsVertical></BsThreeDotsVertical>
                                  </summary>
                                  <ul className="px-4 py-6  menu dropdown-content z-[1] bg-gray-200 pr-10 rounded-md shadow-lg  dark:bg-slate-700 mt-3 duration-500">
                                    <li onClick={() => deleteHandler(user)}>
                                      <button className="text-sm w-full px-8  hover:bg-primary btn-sm border-[1px] border-primary font-semibold hover:text-white dark:bg-slate-300 dark:hover:bg-primary">
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </details>
                              </div>
                            </div>
                            <div className="flex items-center justify-between text-gray-600 px-2 dark:text-gray-200">
                              {user?.adminMessage && (
                                <div>
                                  {user?.adminMessage?.length >= 16 ? (
                                    <>
                                      <p className="mt-1 text-xs 2xl:text-sm">
                                        You:{" "}
                                        {user?.adminMessage?.slice(0, 10) +
                                          "..."}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="mt-1 text-xs 2xl:text-sm">
                                        You: {user?.adminMessage}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}

                              {user?.message && (
                                <div className="mt-1">
                                  {user?.message?.length > 18 ? (
                                    <>
                                      <p className="2xl:text-sm text-xs">
                                        {user?.message?.slice(0, 16) + "..."}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="2xl:text-sm text-xs ">
                                        {user?.message}
                                      </p>
                                    </>
                                  )}
                                </div>
                              )}
                              {user?.time && (
                                <p className="text-xs ">
                                  {new Date(user?.time).toLocaleTimeString(
                                    "en-us",
                                    { month: "short", day: "numeric",hour: "numeric",
                                    minute: "numeric", }
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </li>
                  )
              )}
            </ul>
          }
          
          <div>

            <p className="text-center dark:text-gray-100">Currently Selected Page: <span className="text-primary font-bold">{page + 1}</span></p>

            <div>
              <div className="pagination py-3 flex justify-center">
                <button
                  className="flex items-center pr-2 pl-1 py-1 text-gray-500 ml-2 2xl:ml-3 cursor-pointer border-[1px] border-gray-300
                   hover:bg-primary hover:text-white custom-shadow dark:text-white text-sm"
                  onClick={prevPage}
                  disabled={page === 0}
                >
                <MdArrowBackIosNew className="text-xs font-bold "/> <MdArrowBackIosNew className="text-xs -ml-1 "/>  Prev
                </button>

                <button
                  className="flex items-center  pl-2 py-1 text-gray-500 ml-2 2xl:ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-primary hover:text-white custom-shadow dark:text-white text-sm"
                  onClick={nextPage}
                  disabled={page === pages - 1}
                >
               Next    <MdOutlineNavigateNext className="text-xl"/> <MdOutlineNavigateNext className="text-xl -ml-3" />
                </button>

        

                <select
                  className=" ml-2 2xl:ml-3 dark:bg-gray-400 dark:text-gray-100 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-1 text-xs 2xl:text-sm leading-tight font-medium"
                  onChange={(event) => setSize(event.target.value)}
                >
                  <option
                    selected
                    disabled
                    className="hidden"
                  >{`Page Size ${size}`}</option>

                  <option value="6">Page Size 6</option>
                  <option value="10">Page Size 10</option>
                  <option value="15">Page Size 15</option>
                  <option value="20">Page Size 20</option>
                </select>

              
              </div>

       
            </div>

          </div>


        </div>

        <div className="md:col-span-4 lg:col-span-5 ">
          <AdminConversation
            newUser={newUser}
            users={users}
            setLoad={setLoad}
            load={load}
          ></AdminConversation>
        </div>
      </div>

      <div className="block md:hidden ">
        {
          <ul className="menu p-4 w-[95%] mx-auto text-base mt-5">
            {users?.map(
              (user) =>
                user?.chat && (
                  <Link to={`/dashboard/user/${user?._id}`}>
                    <li key={user?._id} >
                      <div className="my-2 block bg-[#79A4A8] dark:bg-neutral-700 rounded-md text-white">
                        <div className="flex items-center justify-between ">
                          <div
                            //  to={`/dashboard/user/${user?._id}`}
                            className="flex items-center gap-3   w-full  px-2 "
                          >
                            {user?.photoURL && (
                              <img
                                src={user?.photoURL}
                                alt={user?.photoURL}
                                className="w-10 h-10 border-2 border-primary rounded-full"
                               
                              />
                            )}
                            {!user?.photoURL && (
                              <BiUserCircle className="w-12 h-12 rounded-full text-gray-300"></BiUserCircle>
                            )}

                            <p className="dark:text-gray-100">{user?.name}</p>
                          </div>

                          <div>
                            <details className="dropdown dropdown-end  ">
                              <summary
                                type="button"
                                className="
                                        bg-transparent outline-none text-gray-200 dark:text-gray-100 text-2xl -mr mt-1 cursor-pointer"
                              >
                                <BsThreeDotsVertical></BsThreeDotsVertical>
                              </summary>
                              <ul className="px-4 py-6  menu dropdown-content z-[1] bg-gray-200 pr-10 rounded-md shadow-lg  dark:bg-slate-700 mt-3 duration-500">
                                <li onClick={() => deleteHandler(user)}>
                                  <button className="text-sm w-full px-8 text-gray-500 hover:bg-primary btn-sm border-[1px] border-primary font-semibold hover:text-white dark:bg-slate-300 dark:hover:bg-primary">
                                    Delete
                                  </button>
                                </li>
                              </ul>
                            </details>
                          </div>
                        </div>
                        <div className="flex items-center justify-between text-gray-100 px-2 dark:text-gray-200">
                          {user?.adminMessage && (
                            <div>
                              {user?.adminMessage?.length >= 16 ? (
                                <>
                                  <p className="mt-1 text-sm">
                                    You:{" "}
                                    {user?.adminMessage?.slice(0, 10) + "..."}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="mt-1 text-sm">
                                    You: {user?.adminMessage}
                                  </p>
                                </>
                              )}
                            </div>
                          )}
                          {user?.message && (
                            <div className="mt-1">
                              {user?.message?.length > 18 ? (
                                <>
                                  <p className="text-sm t">
                                    {user?.message?.slice(0, 16) + "..."}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="text-sm ">{user?.message}</p>
                                </>
                              )}
                            </div>
                          )}
                          {user?.time && (
                            <p className="text-xs ">
                              {new Date(user?.time).toLocaleTimeString(
                                "en-us",
                                { month: "short", day: "numeric" }
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </li>

                  </Link>
                )
            )}
          </ul>
        }

        <div className="  pt-5  w-full ">
          <p className="text-center  text-sm font-semibold dark:text-gray-100 ml-3 ">
            Currently Selected page:{" "}
            <span className="text-primary">{page + 1}</span>
          </p>
          <div>
            <div>
              <div className=" py-3 flex justify-center">
                <button
                  className="flex items-center pr-2 pl-2 py-1 text-gray-500 ml-2 xl:ml-3 cursor-pointer border-[1px] border-gray-300
                   hover:bg-primary hover:text-white custom-shadow dark:text-white text-sm"

                  onClick={prevPage}
                  disabled={page === 0}
                >
                  <MdArrowBackIosNew className=" font-bold " /> <MdArrowBackIosNew className=" -ml-1 " />  Prev
                </button>

                <button
                  className="flex items-center  pl-2 py-1 text-gray-500 ml-2 xl:ml-3 cursor-pointer border-[1px] border-gray-300 hover:bg-primary hover:text-white custom-shadow dark:text-white text-sm"
                  onClick={nextPage}
                  disabled={page === pages - 1}
                >
                  Next    <MdOutlineNavigateNext className="text-xl" /> <MdOutlineNavigateNext className="text-xl -ml-3" />
                </button>



                <select
                  className="ml-2 xl:ml-3 dark:bg-gray-400 dark:text-gray-100 bg-white text-gray-500 border-[1px] border-gray-300 rounded-md focus:outline-none px-1 text-sm leading-tight font-medium"
                  onChange={(event) => setSize(event.target.value)}
                >
                  <option
                    selected
                    disabled
                    className="hidden"
                  >{`Page Size ${size}`}</option>

                  <option value="6">Page Size 6</option>
                  <option value="10">Page Size 10</option>
                  <option value="15">Page Size 15</option>
                  <option value="20">Page Size 20</option>
                </select>


              </div>

            
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;

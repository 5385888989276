import React, { useState, useEffect } from "react";
import "./CustomCarousel.css";
import { BsDashLg } from "react-icons/bs";

const images = [
  {
    lg: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1707406225/main-banner-5_rn5o9i.webp",
    sm: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1723827428/fwene27hwpfst7n3bokt.webp",
  },
  {
    lg: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1707406056/new-cover-1_pxxx1t.webp",
    sm: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1723827761/p1ptysmsvty0mnjxu0sh.webp",
  },
  {
    lg: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1707406288/cover-banner-2_trcltv.webp",
    sm: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1723827864/r6119qsfzdlhu2eemuq7.webp",
  },
  {
    lg: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1707406444/cover-banner-3_vni9rj.webp",
    sm: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1707425681/Home-care_jjsfok.webp",
  },
  {
    lg: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1707406150/another-contact_u1diga.webp",
    sm: "https://res.cloudinary.com/di3wwp9s0/image/upload/v1723827985/cxrsdkzy0pwszlhnxnuo.webp",
  },
];

const CustomCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const interval = 5000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const promises = images.map((image) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = image.lg;
        img.onload = resolve;
        img.onerror = resolve;
      });
    });

    Promise.all(promises).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="slider-container">
      {isLoading && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}
      <div
        className="banner-text absolute z-20 bottom-0 md:top-[45vh] lg:top-[30vh] w-full"
        id="main-banner-text"
      >
        <div className="mx-auto">
          <div
            className="rounded-md bg-contact-2 md:max-w-3xl lg:max-w-4xl mx-auto"
            id="lower-medium-device"
          >
            <div className="bg-simple px-5 pt-4 pb-8">
              <h1 className="text-white text-base text-center font-normal text-shadow">
                Cottage Home Care Services provides care to seniors in need and
                has taken on some of the most challenging cases in New York.
              </h1>
              <p className="text-white text-xl text-center flex justify-center">
                <BsDashLg />
              </p>
              <h2 className="text-center md:text-xl text-sm font-medium md:font-semibold text-[#dbf2f4] mb-8 px-5 md:px-0 mt-2 berlin custom-font text-shadow">
                “HHA/PCA & CDPAP SERVICES THROUGHOUT NEW YORK CITY, NASSAU &
                SUFFOLK COUNTY, WESTCHESTER & ALBANY”
              </h2>
              <div className="md:mt-[4vh] flex justify-center text-white font-semibold gap-8 second-text">
                <a
                  href="tel:+1516-367-2266"
                  className="px-10 py-4 rounded-full lg:text-xl bg-primary uppercase bg-hov2 coco-gothic"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider">
        {images?.map((img, index) => (
          <div key={index} className={index === currentIndex ? "current" : ""}>
            <picture
              key={index}
              className={index === currentIndex ? "current" : ""}
            >
              <source media="(min-width: 768px)" srcSet={img.lg} />
              <img
                src={img.sm}
                alt={`Image ${index + 1}`}
                className={index === currentIndex ? "current bg-center" : ""}
                width="1080"
                height="1080"
                loading="lazy"
              />
            </picture>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
